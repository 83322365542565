import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CoursesGetAPI2, PlacementListEndedAPI } from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import {
  JobSelectedFieldsAPI,
  JobSelectedLocalStateFieldsAPI,
} from "../../api/JobManagementForm/action";
import {
  AllCompanyAPI2,
  AllJobRolesAPI,
  AllJobRoundsAPI,
  AllJobSegmentAPI,
  AllLocationsAPI,
  DISABLE_FIELDS_POST_JOB,
  GetGroupCollegeListAPI,
  getSinglePostedJobAPI,
  JobPatchAPI,
  JobPostAPI,
  SKillsAPI,
} from "../../api/JobPost/action";
import {
  collegeGroupListSelectorFn,
  collegeIdFn,
  collegeListSelectorFn,
  isCollegeGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import AllChooseCollegeJob from "../../components/Cards/UniversityPostJob/AllChooseCollegeJob";
import AllCollegesGrid from "../../components/Cards/UniversityPostJob/AllCollegesGrid";
import ChooseCategory from "../../components/Cards/UniversityPostJob/ChooseCategory";
import SuccessfullyMsg from "../../components/Cards/UniversityPostJob/SuccessfullyMsg";
import NewPostJobForm from "../../components/Form/PostJobForm/NewPostForm";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../constants/constants";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import checkSelectedGroup from "../../utility/getGroupIdParam";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 2px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #b0b0b0",
    width: "70% ",
    "&:hover": {
      borderColor: "#000",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
    margin: 10,
    marginRight: 0,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function NewPostJob() {
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [step, setStep] = useState(0);

  const classes = useStyles();
  const params = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [returnJobId, setReturnJobId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingJobSegment, setLoadingJobSegment] = useState(false);
  const [loadingJobRoles, setLoadingJobRoles] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [jobAction, setJobAction] = useState("");
  const [jobId, setJobId] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [placementCycle, setPlacementCycle] = useState([]);
  const [loadingjobData, setLoadingjobData] = useState(false);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const groupCollegeList =
    useSelector((state) => state?.GroupCollegeList?.collegeList) || [];
  const [loadingCourses, setLoadingCourses] = useState(false);
  const collegeId = useSelector(collegeIdFn);
  const [coursesArray, setCoursesArray] = useState([]);

  const isGradeSystem = useSelector(isGradeSystemFn);
  const [editReason, setEditReason] = useState(null);
  const [editReasonError, setEditReasonError] = useState(null);

  const search = useLocation().search;
  const placementCycleId = new URLSearchParams(search).get(
    "placement_cycle_id"
  );
  const is_niyukti_job = new URLSearchParams(search).get("is_niyukti_job");

  const initialDisableFields = {
    company: false,
    job_type: false,
    textfield: false,
    autocomplete: false,
    application_date: false,
    date_single: false,
    campus_drive_date: false,
    desc: false,
    ctc_range: false,
    editor: false,
    location: false,
    vacancy: false,
    duration: false,
    ppo: false,
    courses: false,
    skills: false,
    graduation_grade: false,
    school_grade: false,
    batch: false,
    selection: false,
    documentsRequired: false,
    placementCycle: false,
  };

  const disableFields = useRef(initialDisableFields);

  useEffect(() => {
    if (params.job_id) {
      setJobAction(params.job_id.split("-")[0]);
      setJobId(params.job_id.split("-")[1]);

      setStep(3);
    }
  }, [params]);

  useEffect(() => {
    if (selectedGroup?.id) {
      dispatch(SKillsAPI());

      dispatch(
        AllCompanyAPI2(
          setLoadingCompanies,
          alert,
          history,
          location,
          checkSelectedGroup(isCollegeGroup, selectedGroup, collegeGroupList)
        )
      );
      dispatch(
        AllJobSegmentAPI(setLoadingJobSegment, alert, history, location)
      );
      dispatch(AllJobRolesAPI(setLoadingJobRoles, alert, history, location));
      dispatch(AllLocationsAPI(setLoadingLocations, alert, history, location));
      dispatch(AllJobRoundsAPI(alert, history, location));

      const url = `api/manage_job/view/?college_id=${collegeId}&job_status=OPEN`;
      // dispatch(getJobsAPI(setLoadingCards, alert, history, location, url));
      dispatch(
        CoursesGetAPI2(
          setLoadingCourses,
          alert,
          history,
          location,
          checkSelectedGroup(isCollegeGroup, selectedGroup, collegeGroupList)
        )
      );

      dispatch({
        type: DISABLE_FIELDS_POST_JOB,
        payload: initialDisableFields,
      });
    }
  }, [selectedGroup?.id]);

  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );

  const [PlacementFlag, resetPlacementFlag] = useState(false);

  useEffect(() => {
    if (PlacementFlag) {
      setPlacementCycle([]);
    }
  }, [PlacementFlag]);

  const checkForEJD = () => {
    if (jobAction === "ejd") return `&ejd_response=true`;
    else return ``;
  };

  const [fullTimeJobs, setFullTimeJobs] = useState([]);
  const [internships, setInternships] = useState([]);
  const [jobTypeSelected, setJobTypeSelected] = useState(null);

  useEffect(() => {
    if (!jobId) {
      if (selectedForm) {
        dispatch(
          JobSelectedFieldsAPI(
            setLoading,
            alert,
            history,
            location,
            selectedForm
          )
        );
        dispatch(
          JobSelectedLocalStateFieldsAPI(
            alert,
            history,
            location,
            selectedForm,
            jobType,
            setFullTimeJobs,
            setInternships
          )
        );
      }
    } else {
      var url = `api/manage_job/view/job/?job_id=${jobId}${checkForEJD()}`;
      dispatch(
        getSinglePostedJobAPI(
          setLoadingjobData,
          alert,
          history,
          location,
          jobId,
          url
        )
      );
    }
  }, [jobId, selectedForm]);

  useEffect(() => {
    if (!jobId) {
      if (collegeId) {
        dispatch(
          JobSelectedFieldsAPI(
            setLoading,
            alert,
            history,
            location,
            collegeId,
            jobTypeSelected
          )
        );
      }
    } else {
      var url = `api/manage_job/view/job/?job_id=${jobId}${checkForEJD()}`;
      dispatch(
        getSinglePostedJobAPI(setLoading, alert, history, location, jobId, url)
      );
    }
  }, [jobTypeSelected]);

  const selectedFields = useSelector((state) =>
    jobId
      ? state?.SingleJobData?.details?.college_job_data?.fields
      : state?.JobFields?.details?.job_form_fields
  );

  const selectedData = useSelector((state) =>
    jobId ? state?.SingleJobData?.details?.college_job_data : []
  );

  const jobStatus = useSelector((state) =>
    jobId ? state?.SingleJobData?.details : []
  );

  const collegePlacementCycleData = useSelector((state) =>
    jobId ? state?.SingleJobData?.details?.job_college : []
  );

  const [qualifications, setQualifications] = useState({});
  const [dataSelected, setDataSelected] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const jobData = useSelector((state) => state?.JobData?.details);
  const [conditionsText, setConditionsText] = useState(null);
  const [ppoSalaryBreakup, setPpoSalaryBreakup] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateData1 = () => {
    var is_valid = true;
    var company_error = null;
    var job_title_error = null;
    var job_role_error = null;
    var job_segment_error = null;
    var start_date_error = null;
    var end_date_error = null;
    var job_desc_error = null;
    var ctc_min_error = null;
    var ctc_max_error = null;
    var location_error = null;
    var vacancy_error = null;
    var ppo_ctc_max_error = null;
    var ppo_ctc_min_error = null;
    var courses_error = null;
    var min_score_error = null;
    var batch_error = null;
    var round_error = null;
    var duration_error = null;
    var venue_error = null;
    var placement_error = null;
    var skills_error = null;
    var twelve_error = null;
    var tenth_error = null;
    var upload_error = null;

    var company_alert = null;
    var job_title_alert = null;
    var job_role_alert = null;
    var job_segment_alert = null;
    var start_date_alert = null;
    var end_date_alert = null;
    var desc_alert = null;
    var salary_alert = null;
    var location_alert = null;
    var vacancy_alert = null;
    var internship_duration_alert = null;
    var internship_duration_error = null;
    var ppo_salary_alert = null;
    var courses_alert = null;

    var batch_alert = null;
    var selection_alert = null;

    var placement_alert = null;
    var skills_alert = null;
    var school_grading_alert = null;
    var grading_alert = null;
    var edit_reason = null;
    var upload_alert = null;

    var arr_alerts = [];

    if (!groupCollegesList.some((college) => college.checked)) {
      placement_error = "Please Select College and Placement Cycle!!";
      is_valid = false;

      placement_alert = "Placement";
      arr_alerts.push("Placement");
    }
    if (jobAction === "edit" && !editReason) {
      setEditReasonError("Please Enter Reason");
      is_valid = false;
      arr_alerts.push("Edit Reason");
    }

    selectedFields?.length > 0 &&
      selectedFields?.map((item) => {
        if (item.field_type.toLowerCase() === "company") {
          if (!jobDetails?.company) {
            company_error = "Please Enter the company!!";
            is_valid = false;
            company_alert = "Company";
            arr_alerts.push("Company");
          }
        }
        if (
          item.field_type.toLowerCase() === "textfield" &&
          item?.key === "job_title"
        ) {
          if (!jobDetails?.job_title && !formAllTextFieldState?.job_title) {
            job_title_error = "Please Enter Job Title";
            is_valid = false;
            job_title_alert = "Job Title";
            arr_alerts.push("Job Title");
          }
        }
        if (item.field_type.toLowerCase() === "autocomplete") {
          if (item?.key === "job_role") {
            if (!jobDetails?.job_role) {
              job_role_error = "Please Enter Job Role";
              is_valid = false;
              job_role_alert = "Job Role";
              arr_alerts.push("Job Role");
            }
          }
          if (item?.key === "job_segment") {
            if (!jobDetails?.job_segment) {
              job_segment_error = "Please Enter Job Segment";
              is_valid = false;
              job_segment_alert = "Job Segment";
              arr_alerts.push("Job Segment");
            }
          }
        }
        if (item.field_type.toLowerCase() === "application_date") {
          if (!jobDetails?.application_start_date) {
            start_date_error = "Please Enter application start date";
            is_valid = false;
            start_date_alert = "Application Start Date";
            arr_alerts.push("Application Start Date");

            // alert.error("Please Enter Application Start Date!");
          }
          if (!jobDetails?.application_end_date) {
            end_date_error = "Please Enter application end date";
            is_valid = false;
            end_date_alert = "Application End Date";
            arr_alerts.push("Application End Date");

            // alert.error("Please Enter Application End Date!");
          }
        }
        if (item.field_type.toLowerCase() === "job-desc") {
          if (!jobDetails?.job_description) {
            job_desc_error = "Enter Job Description";
            is_valid = false;
            desc_alert = "Job Description";
            arr_alerts.push("Job Description");
          }
        }
        if (item.field_type.toLowerCase() === "ctc_range") {
          if (jobDetails?.salary_type === "PAID") {
            if (!jobDetails?.ctc_min || jobDetails?.ctc_min === 0) {
              ctc_min_error = "Enter Min Ctc";
              is_valid = false;
              salary_alert = "Salary";
              arr_alerts.push("Salary");
            }
            if (!jobDetails?.ctc_max || jobDetails?.ctc_max === 0) {
              ctc_max_error = "Enter Max Ctc";
              is_valid = false;
              salary_alert = "Salary";
              arr_alerts.push("Salary");
            }
            if (jobDetails?.ctc_min && jobDetails?.ctc_max) {
              if (
                parseFloat(jobDetails?.ctc_max) <
                parseFloat(jobDetails?.ctc_min)
              ) {
                alert.error("Please Enter Max CTC more than min CTC");
                ctc_min_error = "Enter correct Min Ctc";
                ctc_max_error = "Enter correct Max Ctc";
                is_valid = false;
                salary_alert = "Salary";
                arr_alerts.push("Salary");
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "location") {
          if (!jobDetails?.pan_india && jobDetails.job_location?.length === 0) {
            location_error = "Please enter job locations";
            is_valid = false;
            location_alert = "Location";
            arr_alerts.push("CompanyLocation");
          }
        }
        if (item.field_type.toLowerCase() === "vacancy") {
          if (!jobDetails?.vacancy || jobDetails?.vacancy === 0) {
            vacancy_error = "Please enter Vacancy";
            is_valid = false;
            vacancy_alert = "Vacancy";
            arr_alerts.push("Vacancy");
          }
        }
        if (item.field_type.toLowerCase() === "duration") {
          if (
            (jobDetails?.job_type_id === 1 &&
              !jobDetails?.internship_duration) ||
            jobDetails?.internship_duration === 0
          ) {
            internship_duration_error = "Please enter duration";
            is_valid = false;
            internship_duration_alert = "duration";
            arr_alerts.push("duration");
          }
        }
        if (item.field_type.toLowerCase() === "ppo") {
          if (jobDetails?.ppo) {
            if (jobDetails?.ppo_salary_type === "PAID") {
              if (!jobDetails?.ppo_ctc_min || jobDetails?.ppo_ctc_min === 0) {
                ppo_ctc_min_error = "Enter Min Ctc";
                is_valid = false;
                ppo_salary_alert = "Salary Ppo";
                arr_alerts.push("Salary Ppo");
              }
              if (!jobDetails?.ppo_ctc_max || jobDetails?.ppo_ctc_max === 0) {
                ppo_ctc_max_error = "Enter Max Ctc";
                is_valid = false;
                ppo_salary_alert = "Salary Ppo";
                arr_alerts.push("Salary Ppo");
              }
              if (jobDetails?.ppo_ctc_min && jobDetails?.ppo_ctc_max) {
                if (
                  parseFloat(jobDetails?.ppo_ctc_max) <
                  parseFloat(jobDetails?.ppo_ctc_min)
                ) {
                  alert.error("Please Enter Max CTC more than min CTC");
                  ctc_min_error = "Enter correct Min Ctc";
                  ctc_max_error = "Enter correct Max Ctc";
                  is_valid = false;
                  ppo_salary_alert = "Salary Ppo";
                  arr_alerts.push("Salary Ppo");
                }
              }
            }
          }
        }
        if (item.field_type.toLowerCase() === "courses") {
          if (jobDetails?.degree_spec_selected?.length === 0) {
            courses_error = "Please Select eligible courses";
            is_valid = false;
            courses_alert = "Specialization";
            arr_alerts.push("Specialization");
          }
        }
        if (item.field_type.toLowerCase() === "skills") {
          if (jobDetails?.skills?.length === 0) {
            skills_error = "Please Select Skills";
            is_valid = false;
            skills_alert = "Skills";
            arr_alerts.push("Skills");
          }
        }

        if (item.field_type.toLowerCase() === "graduation_grade") {
          if (!jobDetails.grad_min_score || jobDetails.grad_min_score === 0) {
            min_score_error = "Please enter mimium score";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "Percentage" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 100)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "CGPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 10)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
          if (
            jobDetails?.grad_scoring === "GPA" &&
            (parseFloat(jobDetails.grad_min_score) < 0 ||
              parseFloat(jobDetails.grad_min_score) > 4)
          ) {
            min_score_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            grading_alert = "Current Education Marks";
            arr_alerts.push("Current Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "Percentage" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 100)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "CGPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 10)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.senior_sec_min_score &&
            jobDetails?.senior_sec_scoring === "GPA" &&
            (parseFloat(jobDetails.senior_sec_min_score) < 0 ||
              parseFloat(jobDetails?.senior_sec_min_score) > 4)
          ) {
            twelve_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "school_grade") {
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "Percentage" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 100))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 100";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }

          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "CGPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 10))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 10";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
          if (
            jobDetails.sec_min_score &&
            jobDetails?.sec_scoring === "GPA" &&
            (Number(jobDetails.sec_min_score) < 0 ||
              Number(jobDetails?.sec_min_score > 4))
          ) {
            tenth_error = "Please enter you minimum score between 0 to 4";
            is_valid = false;
            school_grading_alert = "Previous Education Marks";
            arr_alerts.push("Previous Education Marks");
          }
        }
        if (item.field_type.toLowerCase() === "batch") {
          if (jobDetails?.eligible_batches?.length === 0) {
            batch_error = "Please enter Eligible batches";
            is_valid = false;
            batch_alert = "Btach";
            arr_alerts.push("Btach");
          }
        }
        if (item.field_type.toLowerCase() === "selection") {
          if (jobDetails?.rounds?.length === 0) {
            round_error = "Please select Round";
            duration_error = "Please Enter duration";
            venue_error = "Plese select your venue";
            is_valid = false;
            selection_alert = "Selection Procedure";
            arr_alerts.push("Selection Procedure");
          }
        }
      });

    setJobDetailsError({
      ...jobDetailsError,
      company: company_error,
      job_title: job_title_error,
      job_role: job_role_error,
      job_segment: job_segment_error,
      application_end_date: end_date_error,
      application_start_date: start_date_error,
      job_description: job_desc_error,
      ctc_min: ctc_min_error,
      ctc_max: ctc_max_error,
      job_location: location_error,
      vacancy: vacancy_error,
      ppo_ctc_max: ppo_ctc_max_error,
      ppo_ctc_min: ppo_ctc_min_error,
      degree_spec_selected: courses_error,
      grad_min_score: min_score_error,
      eligible_batches: batch_error,
      rounds: round_error,
      round_duration: duration_error,
      venue: venue_error,
      placement_cycle: placement_error,
      skills: skills_error,
      senior_sec_min_score: twelve_error,
      sec_min_score: tenth_error,
      internship_duration: internship_duration_error,
      upload_doc: upload_error,
    });

    return is_valid;
  };

  const [jobText, setJobText] = useState("");
  const a = [];

  const [formAllTextFieldState, setFormAllTextFieldState] = useState({});

  const handleSubmit = (status) => {
    if (validateData1()) {
      jobDetails["fields"] = selectedFields;
      let college_to_cycle = groupCollegesList.filter(
        (college) => college.checked
      );
      const collegeToCycleIds = college_to_cycle.map(
        (college) => college.details.id
      );
      const selectedPlacementCycleIds = groupCollegesList
        .filter((college) => college.checked)
        .flatMap((college) =>
          college.placementCycles
            .filter((cycle) => cycle.checked)
            .map((cycle) => cycle)
        );
      var data = {
        college_id: collegeToCycleIds,
        college_job_data: jobDetails,
        company_id: jobDetails?.company_id,
        job_status: status,
        placement_cycle: selectedPlacementCycleIds.filter(
          (cycle, index, self) => {
            return index === self.findIndex((c) => c.id === cycle.id);
          }
        ),
        application_start_date: new Date(jobDetails?.application_start_date)
          .toISOString()
          .slice(0, 10),
        application_end_date: new Date(jobDetails?.application_end_date)
          .toISOString()
          .slice(0, 10),
        ...(jobDetails?.campus_drive_date && {
          campus_drive_date: new Date(jobDetails?.campus_drive_date)
            .toISOString()
            .slice(0, 10),
        }),
        college_to_cycle: college_to_cycle,
      };

      if (jobAction === "edit") {
        var arr = [...jobStatus?.notes_list];

        data["notes_list"] = arr;
        data["reason"] = editReason;
      }

      if (jobAction === "draft") {
        var arr = [...jobStatus?.notes_list];

        data["notes_list"] = arr;
        data["reason"] = "Draft Job Posting";
      }

      if (jobAction === "ejd") {
        data["is_ejd_job"] = true;
        data["ejd_job_id"] = jobId;
      }
      setJobText(status === "DRAFTED" ? "Saved" : "Posted");
      let jobDetailsModified = { ...jobDetails, ...formAllTextFieldState };
      data["college_job_data"] = jobDetailsModified;
      if (jobAction === "edit" || jobAction === "draft") {
        dispatch(
          JobPatchAPI(
            data,
            jobAction === "draft" ? setLoadingDraft : setLoadingPost,
            alert,
            history,
            location,
            () => {},
            jobId,
            setJobDetails,
            setReturnJobId,
            setSalaryBreakup,
            setPlacementCycle,
            setJobDesc,
            setDataSelectedNewFormat,
            pushArray,
            setPushArray,
            setCoursesArray,
            selectedCourses,
            setDataSelected,
            setSpecializationInDgegreeId,
            setQualifications,
            handleClosePreview,
            setPpoSalaryBreakup,
            setConditionsText,
            setFormSubmitted,
            true,
            setStep,
            jobAction
          )
        );
        setPlacementCycle([...a]);
      } else {
        dispatch(
          JobPostAPI(
            data,
            setLoadingPost,
            alert,
            history,
            location,
            () => {},
            jobData,
            setReturnJobId,
            jobText,
            setLoadingDraft,
            setJobDetails,
            setSalaryBreakup,
            setPlacementCycle,
            setJobDesc,
            setDataSelectedNewFormat,
            pushArray,
            setPushArray,
            setCoursesArray,
            selectedCourses,
            setDataSelected,
            setSpecializationInDgegreeId,
            setQualifications,
            handleClosePreview,
            setPpoSalaryBreakup,
            setConditionsText,
            setFormSubmitted,
            true,
            setStep
          )
        );
        setPlacementCycle([...a]);
      }
    } else {
      alert.error("Please Fill All the above mandatory fields");
    }
  };

  const [jobDetails, setJobDetails] = useState({
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: 7,
    job_type_name: "Full Time",
    job_role_id: null,
    job_role_name: null,
    document_for_jd: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date: null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    bonus: false,
    salary_type: "NOT DISCLOSED",
    salary_breakup: null,
    wfh: false,
    pan_india: false,
    job_location: [],
    vacancy: null,
    service_bond: false,
    conditions: null,
    ppo: false,
    ppo_ctc_min: null,
    ppo_salary_type: "NOT DISCLOSED",
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: [],
    grad_scoring: isGradeSystem ? "CGPA" : "Percentage",
    grad_min_score: null,
    backlog: false,
    sec_scoring: "Percentage",
    sec_min_score: null,
    senior_sec_scoring: "Percentage",
    senior_sec_min_score: null,
    eligible_batches: [],
    rounds: [],
    documents: [],
    skills: [],
  });

  const [jobDetailsError, setJobDetailsError] = useState({
    company_id: null,
    company_name: null,
    company: null,
    job_title: null,
    job_type_id: null,
    job_type_name: null,
    job_role_id: null,
    job_role_name: null,
    job_role: null,
    job_segment_id: null,
    job_segement_name: null,
    job_segment: null,
    application_start_date: null,
    application_end_date: null,
    joining_date: null,
    campus_drive_date: null,
    job_description: null,
    ctc_min: null,
    ctc_max: null,
    salary_type: null,
    ppo_salary_type: null,
    bonus: null,
    salary_breakup: null,
    wfh: null,
    pan_india: null,
    job_location: null,
    vacancy: null,
    service_bond: null,
    conditions: null,
    ppo: null,
    ppo_ctc_min: null,
    ppo_ctc_max: null,
    salary_breakup_ppo: null,
    degree_spec_selected: null,
    grad_scoring: null,
    grad_min_score: null,
    sec_scoring: null,
    sec_min_score: null,
    senior_sec_scroing: null,
    senior_sec_min_score: null,
    eligible_batches: null,
    rounds: null,
    round_duration: null,
    venue_type: null,
    venue: null,
    documents: null,
    placement_cycle: null,
    skills: null,
    upload_doc: null,
  });

  useEffect(() => {
    if (jobId) {
      setJobDetails(selectedData);
      const updatedGroupCollegesList = groupCollegeList.map((college) => {
        const matchingCollege = selectedData?.college_to_cycle?.find(
          (selectedCollege) => selectedCollege.id === college.id
        );
        return matchingCollege ? matchingCollege : college;
      });
      setGroupCollegesList(updatedGroupCollegesList);
      setSearchState(updatedGroupCollegesList);
      setDataFetched(true);
    }
  }, [selectedFields?.length, jobId, selectedData, groupCollegeList]);

  useEffect(() => {
    if (groupCollegeList.length && !jobId) {
      setGroupCollegesList(groupCollegeList);
      setSearchState(groupCollegeList);
    }
  }, [groupCollegeList, jobId]);

  useEffect(() => {
    if (jobStatus?.placement_cycle?.length > 0) {
      setPlacementCycle(jobStatus?.placement_cycle);
    }
  }, [jobStatus?.placement_cycle]);

  useEffect(() => {
    if (jobId && selectedData && is_niyukti_job === "true") {
      disableFields.current = {
        ...disableFields.current,
        company: !!selectedData?.company || false,
        job_title: !!selectedData?.job_title || false,
        job_role:
          !!(selectedData?.job_role_name && selectedData?.job_role) || false,
        application_date: !!selectedData?.application_start_date || false,
        application_end_date: !!selectedData?.application_end_date || false,
        joining_date: !!selectedData?.joining_date || false,
        desc: !!selectedData?.job_description || false,
        ctc_range: !!(selectedData?.ctc_min && selectedData?.ctc_max) || false,
        // salary_breakup: false,
        salary_type: !!selectedData?.salary_type || false,
        location: selectedData?.job_location?.length > 0 || false,
        vacancy: !!selectedData?.vacancy || false,
        wfh: !!selectedData?.wfh || false,
        pan_india: selectedData?.job_location?.length === 0 || false,
        grad_min_score: !!selectedData?.grad_min_score || false,
        backlog: selectedData?.backlog || false,
        duration: false,
        ppo: false,
        skills: selectedData?.skills?.length > 0 || false,
        graduation_grade: false,
        school_grade: false,
        batch: selectedData?.eligible_batches?.length > 0 || false,
        rounds: selectedData?.rounds?.length > 0 || false,
        documentsRequired: selectedData?.documents?.length > 0 || false,
        job_segment: !!selectedData?.job_segment || false,
        vacancy: !!selectedData?.vacancy || false,
        service_bond: !!selectedData?.service_bond || false,
        service_bond_conditions: !!selectedData?.bond_condition || false,
        placementCycle: !!jobStatus?.placement_cycle?.length > 0 || false,
      };

      dispatch({
        type: DISABLE_FIELDS_POST_JOB,
        payload: disableFields.current,
      });
    } else {
      if (jobAction === "edit") {
        dispatch({
          type: DISABLE_FIELDS_POST_JOB,
          payload: initialDisableFields,
        });
      }
    }
  }, [jobId, is_niyukti_job, selectedData]);

  useEffect(() => {
    if (collegeId)
      dispatch(PlacementListEndedAPI(alert, history, location, collegeId));
  }, [collegeId]);

  const [jobDesc, setJobDesc] = useState(
    jobDetails?.job_description ? jobDetails?.job_description : ""
  );
  const [salaryBreakup, setSalaryBreakup] = useState(
    jobDetails?.salary_breakup
  );
  const [dataSelectedNewFormat, setDataSelectedNewFormat] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const collegeList = useSelector(collegeListSelectorFn);

  const [selectAllCollege, setSelectAllCollege] = useState(false);

  const [jobType, setJobType] = useState("0");

  const [groupCollegesList, setGroupCollegesList] = useState(groupCollegeList);

  const [searchQuery, setSearchQuery] = useState("");

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1)
        return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else
        return `&group_id=${generateCsvForParams(
          collegeGroupList?.map((item) => item.id)
        )}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All")
        return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  // const handleSearch = (event) => {
  //   const query = event.target.value;
  //   setSearchQuery(query);
  //   const filteredList = groupCollegesList?.filter((college) =>
  //     college?.details?.name.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setGroupCollegesList(filteredList);
  // };
  const [searchState, setSearchState] = useState([]);

  const handleSearch = (event) => {
    const query = event?.target?.value || "";
    setSearchQuery(query);

    if (query.trim() === "") {
      setGroupCollegesList(searchState);
    } else {
      const filteredList = searchState?.filter((college) =>
        college?.details?.name.toLowerCase().includes(query.toLowerCase())
      );
      setGroupCollegesList(filteredList);
    }
  };

  const handleSelectAll = (event) => {
    const newSelectAllState = !selectAllCollege;
    setSelectAllCollege(newSelectAllState);

    const updatedCollegesList = groupCollegesList.map((college) => ({
      ...college,
      checked: newSelectAllState,
      placementCycles: college.placementCycles.map((cycle) => ({
        ...cycle,
        checked: newSelectAllState,
      })),
    }));

    setGroupCollegesList(updatedCollegesList);
    setSearchState(updatedCollegesList);
  };
  useEffect(() => {
    if (groupCollegesList.length) {
      const allSelected = groupCollegesList.every((college) => college.checked);
      setSelectAllCollege(allSelected);
    }
  }, [groupCollegesList]);

  useEffect(() => {
    if (selectedGroup?.id)
      dispatch(GetGroupCollegeListAPI(checkSelectedGroup()));
  }, [selectedGroup?.id]);

  const [formsData, setFormsData] = useState([]);

  const [loadingFromData, setLoadingFromData] = useState(true);

  useEffect(() => {
    if (selectedGroup?.id) {
      const url = `api/manage_job/college-form/?${checkSelectedGroup(
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;

      setLoadingFromData(true);

      axios
        .get(BackendBaseApi.SANCHALAN + url, { withCredentials: true })
        .then((res) => {
          if (res?.data?.success) {
            setFormsData(res?.data?.data?.data);
          }
          setLoadingFromData(false);
        })
        .catch((err) => {
          alert.error(`Error: ${err}`);
          setLoadingFromData(false);
        });
    }
  }, [selectedGroup?.id]);

  function getForm() {
    switch (step) {
      case 0:
        return (
          <AllCollegesGrid
            setStep={setStep}
            step={step}
            collegesList={groupCollegesList}
            setCollegesList={setGroupCollegesList}
            setSearchState={setSearchState}
          />
        );
      case 1:
        return (
          <ChooseCategory
            setStep={setStep}
            step={step}
            setJobType={setJobType}
            checked={jobType}
          />
        );
      case 2:
        return (
          <AllChooseCollegeJob
            setStep={setStep}
            step={step}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            formsData={formsData}
            jobType={jobType}
            loadingFromData={loadingFromData}
          />
        );
      case 3:
        return (
          <NewPostJobForm
            jobDetails={jobDetails}
            setJobDetails={setJobDetails}
            jobDetailsError={jobDetailsError}
            setJobDetailsError={setJobDetailsError}
            jobAction={jobAction}
            setJobAction={setJobAction}
            dataFetched={dataFetched}
            loadingCourses={loadingCourses}
            placementCycle={placementCycle}
            placementCycleId={placementCycleId}
            setPlacementCycle={setPlacementCycle}
            jobDesc={jobDesc}
            setJobDesc={setJobDesc}
            salaryBreakup={salaryBreakup}
            setSalaryBreakup={setSalaryBreakup}
            dataSelectedNewFormat={dataSelectedNewFormat}
            setDataSelectedNewFormat={setDataSelectedNewFormat}
            qualifications={qualifications}
            setQualifications={setQualifications}
            coursesArray={coursesArray}
            setCoursesArray={setCoursesArray}
            dataSelected={dataSelected}
            setDataSelected={setDataSelected}
            pushArray={pushArray}
            setPushArray={setPushArray}
            specializationInDegreeId={specializationInDegreeId}
            setSpecializationInDgegreeId={setSpecializationInDgegreeId}
            conditionsText={conditionsText}
            setConditionsText={setConditionsText}
            ppoSalaryBreakup={ppoSalaryBreakup}
            setPpoSalaryBreakup={setPpoSalaryBreakup}
            collegeId={collegeId}
            setFormSubmitted={setFormSubmitted}
            formSubmitted={formSubmitted}
            editReason={editReason}
            setEditReason={setEditReason}
            editReasonError={editReasonError}
            setEditReasonError={setEditReasonError}
            selectedData={selectedData}
            resetPlacementFlag={resetPlacementFlag}
            PlacementFlag={PlacementFlag}
            formAllTextFieldState={formAllTextFieldState}
            setFormAllTextFieldState={setFormAllTextFieldState}
            collegesList={groupCollegesList}
            setCollegesList={setGroupCollegesList}
            setSearchState={setSearchState}
            searchState={searchState}
          />
        );
      case 4:
        return (
          <SuccessfullyMsg
            setStep={setStep}
            step={step}
            handlePostNewJob={handlePostNewJob}
          />
        );
      default:
        return <AllCollegesGrid />;
    }
  }

  const validateData = (step) => {
    let isValid = true;
    switch (step) {
      case 0:
        let isAnyCollegeSelected = false;

        setGroupCollegesList((prev) => {
          return prev.map((college) => {
            if (college.checked) {
              isAnyCollegeSelected = true;
              const isAnyCycleSelected = college.placementCycles.some(
                (cycle) => cycle.checked
              );
              if (!isAnyCycleSelected) {
                isValid = false;
                return { ...college, error: true };
              }
            }
            return college;
          });
        });

        if (!isAnyCollegeSelected) {
          isValid = false;
          alert.error("Please select atleast one college to proceed");
        }

        return isValid;

      case 2:
        if (!selectedForm) {
          alert.error("Select one job posting form");
          isValid = false;
        }
        return isValid;

      default:
        return true;
    }
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (validateData(step)) {
      setSearchQuery("");
      handleSearch("");
      setStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  useEffect(() => {
    if (collegeGroupList?.length) {
      if (collegeGroupList?.length > 0 && isCollegeGroup) {
        setNewCollegeGroupList(
          [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
        );
        setSelectedGroup({ id: "All", name: "All Groups" });
      } else {
        setNewCollegeGroupList(collegeGroupList);
        setSelectedGroup(collegeGroupList[0]);
      }
    }
  }, [collegeGroupList]);

  const handlePostNewJob = () => {
    setStep(0);
    setSelectedForm(null);
    setSelectAllCollege(false);
    setJobType(0);
    setSearchQuery("");
    setGroupCollegesList(groupCollegeList);
    setJobDesc("");
  };

  return (
    <Box style={{ height: "100dvh" }}>
      {step === 0 && (
        <Box>
          {collegeGroupList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <GroupListDropdown
                title="Group"
                state={selectedGroup}
                setState={setSelectedGroup}
                groupList={newCollegeGroupList}
              />
            </div>
          )}
        </Box>
      )}
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="h2">Post Job</Typography>
      </Box>

      <Box
        style={{
          height: step === 1 ? "auto" : "70dvh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px #00000026",
        }}
      >
        {step === 4 ? (
          ""
        ) : (
          <Grid
            container
            spacing={2}
            style={{
              borderBottom: "1px solid #E0E0E0",
              margin: 0,
              width: "100%",
              padding: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
              {step === 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllCollege}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  }
                  label={
                    <Typography style={{ color: "#5B5B5B", fontSize: "16px" }}>
                      Select all colleges and placement cycle
                    </Typography>
                  }
                />
              ) : (
                <Typography style={{ fontWeight: "600", fontSize: "16px" }}>
                  {step === 2
                    ? "Choose College Job Post Form"
                    : step === 3
                    ? "Fill Details"
                    : step === 1
                    ? "Choose Job Category"
                    : ""}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              md={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {step === 0 ? (
                <Paper component="form" className={classes.root}>
                  <IconButton className={classes.iconButton} aria-label="menu">
                    <SearchIcon style={{ color: "#b0b0b0" }} />
                  </IconButton>
                  <InputBase
                    className={classes.input}
                    placeholder="Search Colleges"
                    inputProps={{ "aria-label": "Search Colleges" }}
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </Paper>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}
        <Box
          style={{
            height: step === 1 ? "70dvh" : step === 4 ? "100%" : "56dvh",
            overflow: "scroll",
            overflowX: "hidden",
            overflowY: step === 1 ? "hidden" : "scroll",
            position: "relative",
          }}
        >
          {getForm()}
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Box>
          {step === 3 ? (
            <>
              {/* <Button
                variant="outlined"
                style={{ height: "45px", boxShadow: "none", width: "200px" }}
                onClick={handleClickOpenPreview}
                disabled={loadingPost}
              >
                Preview
              </Button>

              <FullScreenPreviewDialog
                open={openPreview}
                handleClickOpen={handleClickOpenPreview}
                handleClose={handleClosePreview}
              /> */}
            </>
          ) : (
            ""
          )}
        </Box>
        {step === 4 ? (
          ""
        ) : (
          <Box>
            {/* <CollegesPostedJobsDialog open={open} handleClose={handleClose} /> */}
            {jobAction !== "edit" &&
              (step === 0 ? (
                <>
                  {/* {" "}
                <LatestPostedJobs open={open} handleClose={handleClose} />
                <Button
                  variant="outlined"
                  onClick={handleClickOpen}
                  style={{ height: "45px", boxShadow: "none", width: "200px" }}
                >
                  Latest Posted Jobs
                </Button> */}
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    style={{
                      height: "45px",
                      boxShadow: "none",
                      width: "200px",
                    }}
                    onClick={handleBack}
                    disabled={loadingPost}
                  >
                    Back
                  </Button>
                </>
              ))}
            {step === 3 ? (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   style={{
              //     height: "45px",
              //     boxShadow: "none",
              //     marginLeft: 10,
              //     width: "200px",
              //   }}
              //   onClick={() => handleSubmit("OPEN")}
              // >
              //   {loadingPost ? (
              //     <CircularProgress size={14} color="white" />
              //   ) : (
              //     "Post Job"
              //   )}
              // </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  if (licenceData && licenceData?.display) {
                    alert.error(licenceData?.display_message);
                    return;
                  }
                  if (!loadingPost) handleSubmit("OPEN");
                }}
                className={classes.btn}
                style={{
                  height: "45px",
                  boxShadow: "none",
                  marginLeft: 10,
                  width: "200px",
                }}
              >
                {loadingPost ? (
                  <>
                    <CircularProgress
                      className={classes.circularProgress}
                      size={14}
                    />
                  </>
                ) : jobAction === "edit" ? (
                  "Save Changes"
                ) : (
                  "Post Job"
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "45px",
                  boxShadow: "none",
                  marginLeft: 10,
                  width: "200px",
                }}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NewPostJob;
