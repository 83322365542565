import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import pSBC from "shade-blend-color";
import { useSelector } from "react-redux";
import {
  entityInfoSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../api/SignIn/selector";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  btngrid: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "15px",
  },
  add: {
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

function OnlyCollegeList({
  groupDataError,
  setGroupDataError,
  groupData,
  setGroupData,
  mainCollegeList,
  preSelectedList,
  collegeList,
  setCollegeList
}) {
  const classes = useStyles();

  useEffect(() => {
    if (mainCollegeList && mainCollegeList?.length) {
      var arr = [...mainCollegeList];
      arr.forEach((item) => {
        item.is_selected = preSelected(item?.id);
      });
      setCollegeList(arr);
    }
  }, [mainCollegeList?.length]);

  const preSelected = (college_id) => {
    var boole = false;
    if (preSelectedList?.length > 0) {
      if (preSelectedList?.includes(college_id)) boole = true;
    }
    return boole;
  };

  const [showMore, setShowMore] = React.useState(false);
  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;

  const handleDeleteCollege = (id) => {
    setCollegeList((prevList) =>
      prevList.map((college) =>
        college.id === id ? { ...college, is_selected: false } : college
      )
    );
  };

  useEffect(() => {
    if (collegeList?.length) {
      var new_list = collegeList?.filter((i) => i.is_selected);
      setGroupData({ ...groupData, college_list: new_list });
    }
  }, [collegeList]);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        disableCloseOnSelect
        options={collegeList ? collegeList : []}
        getOptionLabel={(option) => option.name}
        style={{ width: "100%", backgroundColor: "#fff" }}
        // value={placementCycle}

        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => <></>);
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <FormGroup>
              <FormControlLabel
                style={{ width: 600 }}
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={option.is_selected}
                    onChange={(e) => {
                      setGroupDataError({
                        ...groupDataError,
                        college_list: null,
                      });

                      var arr = [...collegeList];
                      var index = arr.findIndex((i) => i.id === option?.id);
                      arr[index].is_selected = e.target.checked;
                      setCollegeList(arr);
                    }}
                    name="gilad"
                  />
                }
                onClick={(e) => {
                  e.preventDefault();
                  setGroupDataError({ ...groupDataError, college_list: null });

                  var arr = [...collegeList];
                  var index = arr.findIndex((i) => i.id === option?.id);
                  arr[index].is_selected = e.target.checked;
                  setCollegeList(arr);
                }}
                label={
                  <span style={{ fontSize: 13 }}>
                    {option.name}{" "}
                    {option?.is_blocked ? (
                      <span style={{ color: "red" }}>(Blocked)</span>
                    ) : (
                      <span style={{ color: "#38cb43" }}>(Active)</span>
                    )}
                  </span>
                }
              />
            </FormGroup>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            required
            label={`Select ${entityInfo?.college} in Group`}
            inputProps={{
              ...params.inputProps,
            }}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            error={groupDataError?.college_list ? true : false}
            helperText={<span>{groupDataError?.college_list}</span>}
          />
        )}
      />

      <div component="ul" className={classes.root}>
        {!showMore
          ? groupData?.college_list?.slice(0, 5).map((data, index) => {
              let icon;

              return (
                <li key={data.id}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    onDelete={() => handleDeleteCollege(data.id, index)}
                    deleteIcon={<CloseIcon />}
                    label={data.name}
                    className={classes.chip}
                  />
                </li>
              );
            })
          : groupData?.college_list?.map((data, index) => {
              return (
                <li key={data.id}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    onDelete={() => handleDeleteCollege(data.id, index)}
                    deleteIcon={<CloseIcon />}
                    label={data.name}
                    className={classes.chip}
                  />
                </li>
              );
            })}
      </div>
      <div>
        {groupData?.college_list?.length > 5 && (
          <Button
            className={classes.button}
            onClick={() => {
              setShowMore(!showMore);
            }}
            style={{
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "flex-start",
            }}
            endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            color="primary"
          >
            {showMore ? "Hide" : "View All"}
          </Button>
        )}
      </div>
    </>
  );
}

export default OnlyCollegeList;
