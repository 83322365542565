import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import TuneIcon from "@mui/icons-material/Tune";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import pSBC from "shade-blend-color";
import Calendar from "../../Input/Calendar/index3";
import BackendDrivenShowMore from "./BackendDrivenShowMore";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 5,
    margin: 0,
    boxShadow: "none",
  },
  checkboxroot: {
    padding: 5,
  },
  chip: {
    margin: 5,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
    borderRadius: "8px",
    width: "fit-content",
    maxWidth: "100%",
  },
  chipicon: {
    color: theme.palette.primary.main,
  },
  floatingLabelFocusStyle: {
    color: "#c5c4ba",
    transform: "translate(15px, 14px) scale(1)",
  },
}));

// Add all chip name for ctc filters here and make sure chip name is exactly same as filter's param name(not case sensitive)
const chipNamesForCTCFilterType = ["Ctc", "Cgpa"];

const BackendDrivenFilterDrawer = ({
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  filterDataError,
  clearFilter,
  setClearFilter,

  backendDrivenFilter,
  setBackendDrivenFilter,
  filterData,
  loading,
}) => {
  const classes = useStyles();

  let count = 0;
  const [filterCount, setFilterCount] = useState(0);
  useEffect(() => {
    let count = 0;
    backendDrivenFilter &&
      Object.keys(backendDrivenFilter?.innerFilters).forEach((data) => {
        count += backendDrivenFilter?.innerFilters[data].reduce((acc, item) => {
          if (
            // ((item.chipName === "Ctc" || item.chipName === "Cgpa")
            (chipNamesForCTCFilterType.includes(item.chipName) &&
              item.value &&
              (item.value.min || item.value.max)) ||
            (!chipNamesForCTCFilterType.includes(item.chipName) && item.value)
          ) {
            return acc + 1;
          }
          return acc;
        }, 0);
      });
    setFilterCount(count);
  }, [backendDrivenFilter?.innerFilters]);

  const [tempFilterApplied, setTempFilterApplied] = useState(selectedFilters);
  const [state, setState] = React.useState({
    right: false,
  });
  const [currBackendDrivenFilter, setCurrBackendDrivenFilter] = useState(
    backendDrivenFilter?.innerFilters
  );

  const [chipsData, setChipsData] = useState([]);

  const handleCurrBackendDrivenFilterChange = (
    filterType,
    filterParamName,
    value,
    chipName,
    extra
  ) => {
    const newCurrBackendDrivenFilter = { ...currBackendDrivenFilter };
    switch (filterType) {
      case "single_checkbox":
        if (filterParamName in newCurrBackendDrivenFilter) {
          if (
            newCurrBackendDrivenFilter[filterParamName].some(
              (item) => item.value === value
            )
          ) {
            newCurrBackendDrivenFilter[filterParamName] = [];
          } else {
            newCurrBackendDrivenFilter[filterParamName] = [
              { filterParamName, value, chipName },
            ];
          }
        } else {
          newCurrBackendDrivenFilter[filterParamName] = [
            { filterParamName, value, chipName },
          ];
        }
        setCurrBackendDrivenFilter(newCurrBackendDrivenFilter);
        break;

      case "multiple_checkbox":
      case "autocomplete_multiple_checkbox":
        if (filterParamName in newCurrBackendDrivenFilter) {
          let filter = newCurrBackendDrivenFilter[filterParamName];
          if (filter.some((item) => item.value === value)) {
            filter = filter.filter((item) => item.value !== value);
          } else {
            filter.push({ filterParamName, value, chipName });
          }
          newCurrBackendDrivenFilter[filterParamName] = filter;
        } else {
          newCurrBackendDrivenFilter[filterParamName] = [
            { filterParamName, value, chipName },
          ];
        }
        setCurrBackendDrivenFilter(newCurrBackendDrivenFilter);
        break;

      case "ctc":
        if (filterParamName in newCurrBackendDrivenFilter) {
          newCurrBackendDrivenFilter[filterParamName] = [
            {
              filterParamName,
              value:
                extra == "min"
                  ? {
                      ...newCurrBackendDrivenFilter[filterParamName][0].value,
                      min: value ? value : null,
                    }
                  : {
                      ...newCurrBackendDrivenFilter[filterParamName][0].value,
                      max: value ? value : null,
                    },
              chipName,
            },
          ];
        } else {
          newCurrBackendDrivenFilter[filterParamName] = [
            {
              filterParamName,
              value:
                extra == "min"
                  ? {
                      min: value,
                    }
                  : { max: value },
              chipName,
            },
          ];
        }
        setCurrBackendDrivenFilter(newCurrBackendDrivenFilter);
        break;

      case "date":
        if (filterParamName in newCurrBackendDrivenFilter) {
          newCurrBackendDrivenFilter[filterParamName] = [
            { filterParamName, value, chipName },
          ];
        } else {
          newCurrBackendDrivenFilter[filterParamName] = [
            { filterParamName, value, chipName },
          ];
        }
        setCurrBackendDrivenFilter(newCurrBackendDrivenFilter);
        break;
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerOnClick = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const [dateFilter, setDateFilter] = useState(null);
  const [dateFilterParamName, setDateFilterParamName] = useState(null);
  useEffect(() => {
    handleCurrBackendDrivenFilterChange(
      "date",
      dateFilterParamName,
      dateFilter,
      "Date"
    );
  }, [dateFilter]);

  const selectFilter = (
    filterType,
    filterData,
    filterHeading,
    filterParamName
  ) => {
    switch (filterType) {
      // case "single_checkbox":
      //   return filterData.length ? (
      //     <>
      //       <Typography variant="body2" style={{ fontWeight: "bold", fontSize:"16px" }}>
      //         {filterHeading}
      //       </Typography>

      //       <div className={classes.checkboxroot}>
      //         <FormControl component="fieldset" className={classes.formControl}>
      //           <FormGroup>
      //             {filterData.map((displayData) => (
      //               <FormControlLabel
      //                 control={
      //                   <Checkbox
      //                     color="primary"
      //                     size="small"
      //                     name="gilad"
      //                     key={displayData.id}
      //                     checked={
      //                       currBackendDrivenFilter[filterParamName] &&
      //                       currBackendDrivenFilter[filterParamName].some(
      //                         (item) => item.value === displayData.id
      //                       )
      //                         ? true
      //                         : false
      //                     }
      //                     onChange={(e) => {
      //                       handleCurrBackendDrivenFilterChange(
      //                         filterType,
      //                         filterParamName,
      //                         displayData?.id,
      //                         displayData?.name
      //                       );
      //                     }}
      //                   />
      //                 }
      // label={displayData?.name}
      // label={
      //   <Typography
      //     variant="body2"
      //     style={{ fontSize: "14px" }}
      //   >
      //     {displayData?.name}
      //   </Typography>
      // }
      //               />
      //             ))}
      //           </FormGroup>
      //         </FormControl>
      //       </div>
      //     </>
      //   ) : null;
      //   break;

      case "single_checkbox":
        return filterData.length ? (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>

            <div className={classes.checkboxroot}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {filterData.slice(0, 4).map((displayData) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          name="gilad"
                          key={displayData.id}
                          checked={
                            currBackendDrivenFilter[filterParamName] &&
                            currBackendDrivenFilter[filterParamName].some(
                              (item) => item.value === displayData.id
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            handleCurrBackendDrivenFilterChange(
                              filterType,
                              filterParamName,
                              displayData?.id,
                              displayData?.name
                            );
                          }}
                        />
                      }
                      // label={displayData?.name}

                      label={
                        <Typography
                          variant="body2"
                          style={{ fontSize: "14px" }}
                        >
                          {displayData?.name}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
            {filterData.length > 4 && (
              <BackendDrivenShowMore
                data={filterData}
                heading={filterData?.heading}
                currBackendDrivenFilter={currBackendDrivenFilter}
                filterParamName={filterParamName}
                filterType={filterType}
                handleCurrBackendDrivenFilterChange={
                  handleCurrBackendDrivenFilterChange
                }
              />
            )}
          </>
        ) : null;
        break;

      case "multiple_checkbox":
        return filterData.length ? (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>

            <div className={classes.checkboxroot}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {filterData.map((displayData) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          name="gilad"
                          checked={
                            currBackendDrivenFilter[filterParamName] &&
                            currBackendDrivenFilter[filterParamName].some(
                              (item) => item.value === displayData.id
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            handleCurrBackendDrivenFilterChange(
                              filterType,
                              filterParamName,
                              displayData.id,
                              displayData?.name
                            );
                          }}
                        />
                      }
                      // label={displayData?.name}
                      label={
                        <Typography
                          variant="body2"
                          style={{ fontSize: "14px" }}
                        >
                          {displayData?.name}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </>
        ) : null;
        break;

      case "autocomplete_multiple_checkbox":
        return filterData.length ? (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>

            <Autocomplete
              id="select-demo"
              options={filterData}
              getOptionLabel={(option) => String(option.name)}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.name}
                </Typography>
              )}
              onChange={(e, newValue) => {
                handleCurrBackendDrivenFilterChange(
                  filterType,
                  filterParamName,
                  newValue?.id,
                  newValue?.name
                );
              }}
              style={{
                background: "#fff",
                borderRadius: 30,
                marginTop: 10,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={filterData.heading}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
            <div className={classes.checkboxroot}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {filterData.slice(0, 4).map((displayData) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          name="gilad"
                          checked={
                            currBackendDrivenFilter[filterParamName] &&
                            currBackendDrivenFilter[filterParamName].some(
                              (item) => item.value === displayData.id
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            handleCurrBackendDrivenFilterChange(
                              filterType,
                              filterParamName,
                              displayData.id,
                              displayData?.name
                            );
                          }}
                        />
                      }
                      // label={displayData?.name}
                      label={
                        <Typography
                          variant="body2"
                          style={{ fontSize: "14px" }}
                        >
                          {displayData?.name}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
            {filterData.length > 4 && (
              <BackendDrivenShowMore
                data={filterData}
                heading={filterData?.heading}
                currBackendDrivenFilter={currBackendDrivenFilter}
                filterParamName={filterParamName}
                filterType={filterType}
                handleCurrBackendDrivenFilterChange={
                  handleCurrBackendDrivenFilterChange
                }
              />
            )}
          </>
        ) : null;
        break;

      case "toggle_switch":
        return filterData.length ? (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>

            <Switch
              // checked={tempFilterApplied[
              //   filterData[item]?.param_name
              // ]?.some((item) => item?.id === 1)}
              // onChange={() => {
              //   let param_name = filterData[item]?.param_name;
              //   if (
              //     tempFilterApplied[param_name]?.some(
              //       (item) => item?.id === 1
              //     )
              //   ) {
              //     setTempFilterApplied({
              //       ...tempFilterApplied,
              //       [param_name]: [],
              //     });
              //   } else {
              //     setTempFilterApplied({
              //       ...tempFilterApplied,
              //       [param_name]: [{ id: 1, name: "WFH" }],
              //     });
              //   }
              // }}
              color="primary"
            />
          </>
        ) : null;
        break;

      case "ctc":
        return (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>

            <div style={{ paddingBottom: "10px" }}>
              <TextField
                label="Minimum"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                inputProps={{
                  style: { padding: "16px" },
                }}
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  width: "100%",
                }}
                value={
                  currBackendDrivenFilter[filterParamName] &&
                  currBackendDrivenFilter[filterParamName][0]?.value !== null
                    ? currBackendDrivenFilter[filterParamName][0]?.value?.min
                    : ""
                }
                onChange={(e) => {
                  handleCurrBackendDrivenFilterChange(
                    filterType,
                    filterParamName,
                    e.target.value,
                    chipNamesForCTCFilterType.find(
                      (chip) =>
                        chip.toLowerCase() === filterParamName.toLowerCase()
                    ),

                    // "Ctc",
                    "min"
                  );
                }}
              />

              <TextField
                label="Maximum"
                type="number"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                inputProps={{
                  style: { padding: "16px" },
                }}
                style={{ width: "100%" }}
                value={
                  currBackendDrivenFilter[filterParamName] &&
                  currBackendDrivenFilter[filterParamName][0]?.value !== null
                    ? currBackendDrivenFilter[filterParamName][0]?.value?.max
                    : ""
                }
                onChange={(e) => {
                  handleCurrBackendDrivenFilterChange(
                    filterType,
                    filterParamName,
                    e.target.value,
                    chipNamesForCTCFilterType.find(
                      (chip) =>
                        chip.toLowerCase() === filterParamName.toLowerCase()
                    ),
                    // "Ctc",
                    "max"
                  );
                }}
              />
            </div>
          </>
        );
        break;

      case "date":
        if (dateFilterParamName !== filterParamName) {
          setDateFilterParamName(filterParamName);
        }
        return (
          <>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {filterHeading}
            </Typography>
            <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
              <Calendar
                title={`Filter by ${filterHeading}`}
                selectedDate={
                  currBackendDrivenFilter[filterParamName] &&
                  currBackendDrivenFilter[filterParamName][0]
                    ? currBackendDrivenFilter[filterParamName][0].value
                    : null
                }
                setSelectedDate={setDateFilter}
              />
            </div>
          </>
        );
        break;
    }
  };

  useEffect(() => {
    let count = 0;
    currBackendDrivenFilter &&
      Object.keys(currBackendDrivenFilter).forEach((data) => {
        count += currBackendDrivenFilter[data].reduce((acc, item) => {
          if (
            (chipNamesForCTCFilterType.includes(item.chipName) &&
              item.value &&
              (item.value.min || item.value.max)) ||
            (!chipNamesForCTCFilterType.includes(item.chipName) && item.value)
          ) {
            return acc + 1;
          }
          return acc;
        }, 0);
      });
    setFilterCount(count);
  }, [currBackendDrivenFilter]);

  const handleDeleteChip = (chipData) => {
    setCurrBackendDrivenFilter((prevState) => {
      const newCurrBackendDrivenFilter = { ...prevState };
      const filterParamName = chipData?.filterParamName;

      if (newCurrBackendDrivenFilter[filterParamName]) {
        const updatedFilter = newCurrBackendDrivenFilter[filterParamName].map(
          (item) => {
            if (item.value === chipData?.value) {
              return { ...item, value: null };
            }
            return item;
          }
        );

        newCurrBackendDrivenFilter[filterParamName] = updatedFilter;
      }

      return newCurrBackendDrivenFilter;
    });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            backgroundColor: "#fff",
            width: "inherit",
            zIndex: 100,
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <Typography variant="h6">
            Filters {filterCount ? `(${filterCount})` : ""}
          </Typography>
          {filterData && (
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "35px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "8px",
                display: "flex",
                boxShadow: "none",
              }}
              onClick={() => {
                toggleDrawerOnClick(anchor, false);
                setBackendDrivenFilter({
                  ...backendDrivenFilter,
                  innerFilters: currBackendDrivenFilter,
                });
              }}
            >
              Apply
            </Button>
          )}
        </div>

        <div style={{ marginTop: "60px" }}>
          <Paper component="ul" className={classes.root}>
            {currBackendDrivenFilter &&
              Object.keys(currBackendDrivenFilter).map(
                (data) =>
                  currBackendDrivenFilter[data].length > 0 &&
                  currBackendDrivenFilter[data].map((item, index) => (
                    <li key={index} style={{ display: "contents" }}>
                      {chipNamesForCTCFilterType.includes(item.chipName) &&
                        item.value &&
                        (item.value.min || item.value.max) && (
                          <Chip
                            variant="outlined"
                            label={
                              <Typography variant="body2" noWrap>
                                {item.chipName}
                              </Typography>
                            }
                            onDelete={() => handleDeleteChip(item)}
                            size="small"
                            className={classes.chip}
                            deleteIcon={
                              <CloseIcon className={classes.chipicon} />
                            }
                          />
                        )}
                      {!chipNamesForCTCFilterType.includes(item.chipName) &&
                        item.value && (
                          <Chip
                            variant="outlined"
                            label={item.chipName}
                            onDelete={() => handleDeleteChip(item)}
                            size="small"
                            className={classes.chip}
                            deleteIcon={
                              <CloseIcon className={classes.chipicon} />
                            }
                          />
                        )}
                    </li>
                  ))
              )}
          </Paper>
        </div>
        <div style={{ padding: "10px" }}>
          {filterData &&
            filterData.map((filter) =>
              selectFilter(
                filter?.field_type,
                filter?.data,
                filter?.heading,
                filter?.param_name
              )
            )}
        </div>
      </>
    </div>
  );

  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            color="primary"
            endIcon={<TuneIcon style={{ color: "#B2B2B2" }} />}
            variant="outlined"
            onClick={() => {
              toggleDrawer(anchor, true)();
              setCurrBackendDrivenFilter(backendDrivenFilter?.innerFilters);
            }}
            fullWidth
            disabled={loading}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "normal",
              width: "140px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#fff",
              borderColor: "#b0b7c3",
            }}
          >
            <span style={{ color: "#B2B2B2" }}>
              {" "}
              Filter {filterCount ? `(${filterCount})` : ""}
            </span>
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => {
              toggleDrawer(anchor, false)();
              setCurrBackendDrivenFilter(backendDrivenFilter?.innerFilters);
            }}
            // onOpen={toggleDrawer(anchor, true)}
          >
            {filterLoading ? (
              <CircularProgress size={24} />
            ) : filterDataError ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                }}
              >
                <p align="center" style={{ margin: 20 }}>
                  {" "}
                  Some Error Occurred
                  <br />
                  <span style={{ color: "red" }}>"{filterDataError}"</span>
                </p>
              </div>
            ) : (
              <> {list(anchor)}</>
            )}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default BackendDrivenFilterDrawer;
