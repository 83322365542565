import {
  Paper,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  RadioGroup,
  Select,
  Radio,
  Button,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import JobProfile from "../../Form/JobProfile";
import MyEditor from "../../Form/Editor";
import Calendar from "../../Input/Calendar";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import CustomizedRadios from "../../Input/CustomizedRadios";
import AddNewCompany from "../../DialogSection/AddNewCompany";
import Editor from "../../Editor";
import ChipCourses from "../../Form/ChipCourses";
import DegreeSpecializationCustom from "../../DegreeSpecializationCustom";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import UploadDoc from "../../Input/UploadDoc/UploadDoc";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: "20px",
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    padding: "15px",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  textField: {
    marginTop: "10px",
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  rightpaper: {
    height: "auto",
    boxShadow: "none",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  heading: {
    paddingTop: "20px",
    paddingBottom: "20px",
    marginLeft: "40px",
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  textfield: {
    padding: "16px",
  },
  addbtn: {
    height: "48px",
    width: "100%",
    borderRadius: "8px",
    boxShadow: "none",
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginBottom: "50px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginBottom: "50px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginBottom: "70px",
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function MaangeJob({
  localFieldList,
  setLocalFieldList,
  userRolesPermission,
  loadingData,
  fieldList,
  job_type,
  disableAll = false,
  collegeDetail = {},
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    companyname: "",
    name: "hai",
  });
  const [groupFieldList, setGrouoFieldList] = useState(false);
  const [editor, setEditor] = React.useState("");
  const [dataSelected, setDataSelected] = useState([]);
  const [specializationInDegreeId, setSpecializationInDgegreeId] = useState([]);
  const [pushArray, setPushArray] = useState([]);
  const [coursesArray, setCoursesArray] = useState([
    {
      education_id: 1,
      education_name: "Graduation",
      degrees: [
        {
          degree_id: 1,
          degree_name: "B.Tech",
          select_all: false,
          specialization: [
            {
              degree_id: 1,
              specialization_id: 1,
              specialization_name: "Computer Science",
              selected: false,
            },
            {
              degree_id: 1,
              specialization_id: 2,
              specialization_name: "Electronics and Communication Engineering",
              selected: false,
            },
          ],
        },
        {
          degree_id: 2,
          degree_name: "B.Com",
          select_all: false,
          specialization: [
            {
              degree_id: 2,
              specialization_id: 3,
              specialization_name: "Economics",
              selected: false,
            },
            {
              degree_id: 2,
              specialization_id: 4,
              specialization_name: "Marketing",
              selected: false,
            },
          ],
        },
      ],
    },
    {
      education_id: 2,
      education_name: "Post Graduation",
      degrees: [
        {
          degree_id: 3,
          degree_name: "M.Tech",
          select_all: false,
          specialization: [
            {
              degree_id: 3,
              specialization_id: 5,
              specialization_name: "Computer Science",
              selected: false,
            },
            {
              degree_id: 3,
              specialization_id: 6,
              specialization_name: "Electronics and Communication Engineering",
              selected: false,
            },
          ],
        },
        {
          degree_id: 4,
          degree_name: "M.Com",
          select_all: false,
          specialization: [
            {
              degree_id: 4,
              specialization_id: 7,
              specialization_name: "Economics",
              selected: false,
            },
            {
              degree_id: 4,
              specialization_id: 8,
              specialization_name: "Marketing",
              selected: false,
            },
          ],
        },
      ],
    },
  ]);
  const checkChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const selectedFields = useSelector((state) => state?.JobFields?.details);

  useEffect(() => {
    if (fieldList?.length > 0) {
      var arr = [...fieldList];
      arr?.forEach((element) => {
        element["is_selected"] = checkIfExists(element);
      });
      setLocalFieldList(arr);
    }
  }, [selectedFields, fieldList, job_type]);

  useEffect(() => {
    if (localFieldList?.length > 0) {
      var result = localFieldList.reduce(function (r, a) {
        if (a.section) {
          r[a.section] = r[a.section] || [];
          r[a.section].push(a);
        } else {
          r["top"] = r[["top"]] || [];

          r["top"].push(a);
        }

        return r;
      }, Object.create(null));
      setGrouoFieldList(result);
    }
  }, [localFieldList]);

  const checkIfExists = (i) => {
    var bool_ = false;
    if (selectedFields && selectedFields?.job_form_fields?.length > 0) {
      selectedFields?.job_form_fields?.map((item) => {
        if (item.id === i.id || i.pre_selected) bool_ = true;
      });
    } else {
      if (i.pre_selected) bool_ = true;
    }
    return bool_;
  };

  const handleSelectionChange = (e, item, index) => {
    var arr = [...localFieldList];
    item.is_selected = e.target.checked;
    if (index !== -1) {
      arr[index] = item;
      setLocalFieldList(arr);
    }
  };
  const isGradeSystem = useSelector(isGradeSystemFn);

  function getFieldContent(step, item, index) {
    switch (item.field_type) {
      case "upload-file":
        return (
          <Grid mt={2} item xs={9}>
            <div className={clsx(classes.column, classes.helper)}>
              <Paper className={classes.rightpaper}>
                <UploadDoc disabled={true} />
              </Paper>
            </div>
          </Grid>
        );

      case "company":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected || disableAll}
                      >
                        <InputLabel
                          htmlFor="outlined-age-native-simple"
                          className={classes.floatingLabelFocusStyle}
                        >
                          Company Name
                        </InputLabel>
                        <Select
                          //value={state.age}
                          onChange={handleChange}
                          label="Company Name"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <MenuItem value={10}>Demo Company 1</MenuItem>
                          <MenuItem value={20}>Demo Company 2</MenuItem>
                          <MenuItem value={30}>Demo Company 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        disabled
                        // style={{
                        //   borderRadius: "30px",
                        //   boxShadow: "none",
                        //   display: "flex",
                        //   height: "48px",
                        //   paddingLeft: "30px",
                        //   paddingRight: "30px",

                        //   width: "100%",
                        // }}
                        className={classes.addbtn}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "job_type":
        return (
          <>
            {/* <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography variant="h6" style={{ paddingTop: "15px" }}>
                    Basic Info
                  </Typography>
                  <div>
                    <FormControl component="fieldset" disabled={item.is_selected}>

                      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios" className={classes.customize}>
                        <Typography variant="body2" style={{ marginRight: "10px", fontWeight: "bold" }}>
                          Job Type
                        </Typography>
                        <FormControlLabel value="Full-Time" control={<StyledRadio />} label={<Typography variant="body2"> Full-Time</Typography>} />
                        <FormControlLabel value="Internship" control={<StyledRadio />} label={<Typography variant="body2"> Internship</Typography>} />
                        <FormControlLabel value="Part-Time" control={<StyledRadio />} label={<Typography variant="body2"> Part-Time</Typography>} />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Paper>
              </div>
            </Grid> */}
          </>
        );
      case "autocomplete":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    disabled={item.is_selected || disableAll}
                  >
                    <InputLabel
                      required
                      className={classes.floatingLabelFocusStyle}
                    >
                      {item.field_name}
                    </InputLabel>
                    <Select
                      value={state.job}
                      onChange={handleChange}
                      label={item.field_name}
                      inputProps={{
                        style: {
                          padding: "16px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      <MenuItem value={10}>Demo 1</MenuItem>
                      <MenuItem value={20}>Demo 2</MenuItem>
                      <MenuItem value={30}>Demo 3</MenuItem>
                    </Select>
                  </FormControl>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "textfield":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    disabled={item.is_selected || disableAll}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    inputProps={{ className: classes.textfield }}
                    id="title"
                    label={item.field_name}
                    name="Title"
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "application_date":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs={4}>
                      <Calendar title="Application Start Date" disabled />
                    </Grid>
                    <Grid item xs={4}>
                      <Calendar title="Application End Date" disabled />
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "date_single":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Calendar title="Joining Date" disabled />
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "campus_drive_date":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Calendar title="Drive Date" disabled />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "job-desc":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    style={{ marginTop: "10px" }}
                  >
                    Details
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "15px" }}
                  >
                    Job Description*
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor state={editor} setState={setEditor} disabled />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "ctc_range":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "15px" }}
                >
                  <Typography variant="h6" style={{ marginTop: "10px" }}>
                    CTC Range
                  </Typography>

                  <div style={{ display: "flex" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected || disableAll}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Minimum"
                      name="Minimum"
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected || disableAll}
                      style={{ marginLeft: "20px" }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Maximum"
                      name="Maximum"
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        name="checkedB"
                        size="small"
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        Including Bonuses
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "editor":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    {item.field_name}
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor
                      state={editor}
                      setState={setEditor}
                      disabled={disableAll}
                    />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "location":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "15px" }}
                >
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected || disableAll}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Location
                        </InputLabel>
                        <Select
                          native
                          value={state.job}
                          onChange={handleChange}
                          label=" Location"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value={10}>Ten</option>
                          <option value={20}>Twenty</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ display: "grid" }}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      >
                        Add Location
                      </Button>
                    </Grid>
                  </Grid>
                  <FormControl
                    component="fieldset"
                    disabled={item.is_selected || disableAll}
                    style={{ paddingTop: "10px" }}
                  >
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="Pan"
                        control={<Radio color="primary" />}
                        // label="Pan India"
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            PAN India
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        size="small"
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Work From Home Available
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "vacancy":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    // disabled={item.is_selected}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Number of Vacancies"
                    name="Number of Vacancies"
                    disabled
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        size="small"
                        onChange={checkChange}
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Include Service Agreement/ Bond
                      </Typography>
                    }
                  />
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                    Conditions Text
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor
                      state={editor}
                      setState={setEditor}
                      disabled={disableAll}
                    />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "duration":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    // disabled={item.is_selected}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Duration(in months)"
                    name="Duration(in months)"
                    disabled
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "ppo":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={checkChange}
                        size="small"
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        PPO After Internship
                      </Typography>
                    }
                  />
                  <Typography variant="h6" style={{ marginTop: "10px" }}>
                    CTC Range
                  </Typography>

                  <div style={{ display: "flex" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected || disableAll}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{ className: classes.textfield }}
                      label="Minimum"
                      name="Minimum"
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      style={{ marginLeft: "20px" }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      disabled={item.is_selected || disableAll}
                      inputProps={{ className: classes.textfield }}
                      label="Maximum"
                      name="Maximum"
                    />
                  </div>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "15px" }}
                  >
                    Salary Breakup
                  </Typography>
                  {/* <MyEditor /> */}
                  <div className={classes.upload}>
                    <Editor
                      state={editor}
                      setState={setEditor}
                      disabled={disableAll}
                    />
                  </div>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "courses":
        return (
          <>
            <Grid item xs={9}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  {/* <ChipCourses
                    data={dataSelected}
                    setData={setDataSelected}
                    specialization={specializationInDegreeId}
                    setSpecialization={setSpecializationInDgegreeId}
                    coursesArray={coursesArray}
                    setCoursesArray={setCoursesArray}
                    pushArray={pushArray}
                  /> */}
                  <br />

                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      <DegreeSpecializationCustom
                        data={dataSelected}
                        setData={setDataSelected}
                        specialization={specializationInDegreeId}
                        setSpecialization={setSpecializationInDgegreeId}
                        coursesArray={coursesArray}
                        pushArray={pushArray}
                        setPushArray={setPushArray}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {/* <Button variant="contained" style={{ borderRadius: "30px", boxShadow: "none" }} color="primary">
                      Add Course
                    </Button> */}
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );
      case "skills":
        return (
          <>
            <Grid
              item
              xs={9}
              className={classes.dropdowngrid}
              //style={{ marginTop: "20px" }}
            >
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected || disableAll}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Skills
                        </InputLabel>
                        <Select
                          native
                          // value={"Skills"}
                          // onChange={handleChange}
                          label="Skills Required"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                          disabled
                        >
                          {/* <option value={10}>C</option>
                          <option value={20}>C++</option>
                          <option value={20}>Management</option> */}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "graduation_grade":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "20px" }}
                >
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Grades
                  </Typography>
                  {/* <div>
                    {" "}
                    <FormControl disabled={item.is_selected}>

                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Scoring System Graduation*
                        </Typography>
                        <FormControlLabel
                          value="CGPA"
                          control={<StyledRadio />}
                          label={<Typography variant="body2">CGPA</Typography>}
                        />
                        <FormControlLabel
                          value="Percentage"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Percentage</Typography>
                          }
                        />
                        <FormControlLabel
                          value="GPA"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              GPA out of 4
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div> */}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    disabled
                    inputProps={{ className: classes.textfield }}
                    label={`Minimum Score Required in Graduation ${
                      isGradeSystem ? "(CGPA)" : "(Percentage)"
                    }`}
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "school_grade":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "23px" }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Minimum Score Required in 12th Grade(%)"
                  />
                </Paper>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "23px" }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled
                    style={{ width: "50%" }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ className: classes.textfield }}
                    label="Minimum Score Required in 10th Grade"
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "batch":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper className={classes.rightpaper}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={9}>
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected || disableAll}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Eligible Batches
                        </InputLabel>
                        <Select
                          native
                          value={""}
                          onChange={handleChange}
                          label="Eligible Batches"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                          disabled
                        >
                          <option aria-label="None" value="" />
                          <option value={10}>Ten</option>
                          <option value={20}>Twenty</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ display: "grid" }}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled
                      >
                        Add Batch
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </>
        );

      case "selection":
        return (
          <>
            <Grid item xs={9} className={classes.dropdowngrid}>
              <div className={clsx(classes.column, classes.helper)}>
                <Paper
                  className={classes.rightpaper}
                  style={{ paddingTop: "20px" }}
                >
                  <Typography variant="h6">Selection Procedure</Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={9}
                      className={classes.dropdowngrid}
                      style={{ marginTop: "10px" }}
                    >
                      {" "}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        disabled={item.is_selected || disableAll}
                      >
                        <InputLabel
                          className={classes.floatingLabelFocusStyle}
                          required
                        >
                          Job Round Name
                        </InputLabel>
                        <Select
                          native
                          value={state.job}
                          onChange={handleChange}
                          label="Job Round Name"
                          inputProps={{
                            style: {
                              padding: "16px",
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value={10}>Ten</option>
                          <option value={20}>Twenty</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        className={classes.addbtn}
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      >
                        Add Process
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <form className={classes.container} noValidate>
                    <TextField
                      id="time"
                      variant="outlined"
                      label="Duration of the Round"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      disabled={item.is_selected || disableAll}
                      type="time"
                      inputProps={{ className: classes.textfield }}
                      defaultValue="12:00"
                      className={classes.textField}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // inputProps={{
                      //   step: 300, // 5 min
                      // }}
                    />
                  </form>
                  <div style={{ paddingTop: "15px" }}>
                    {" "}
                    <FormControl component="fieldset">
                      <RadioGroup
                        defaultValue="female"
                        aria-label="gender"
                        name="customized-radios"
                        className={classes.customize}
                        disabled={item.is_selected || disableAll}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          Venue Type:
                        </Typography>
                        <FormControlLabel
                          value="Online"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2"> Online</Typography>
                          }
                        />
                        <FormControlLabel
                          value="Offline"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">Offline</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <TextField
                    className={classes.formControl}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    disabled={item.is_selected || disableAll}
                    inputProps={{ className: classes.textfield }}
                    label="Link for the Meeting"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={state.checkedB}
                        onChange={checkChange}
                        name="checkedB"
                        color="primary"
                        disabled={item.is_selected || disableAll}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        Documents Required
                      </Typography>
                    }
                  />
                </Paper>
              </div>
            </Grid>
          </>
        );

      default:
        return <></>;
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.heading}>
          {disableAll
            ? `${
                job_type?.includes("full-time") ? "Job" : "Internship"
              } Posting Form - ${collegeDetail?.name}`
            : `Create ${
                job_type?.includes("full-time") ? "Job" : "Internship"
              } Posting Form`}
        </Typography>
        <Grid container spacing={0}>
          {localFieldList?.length > 0 &&
            localFieldList?.map((item2, index2) => (
              <>
                {item2?.field_type !== "job_type" && (
                  <Grid item xs={3}>
                    <Paper className={classes.leftpaper}>
                      {" "}
                      <FormControlLabel
                        value="end"
                        className={classes.include}
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={item2?.is_selected}
                            onChange={(e) => {
                              if (!item2?.pre_selected)
                                handleSelectionChange(e, item2, index2);
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            Include in Form
                          </Typography>
                        }
                        labelPlacement="end"
                        disabled={item2?.pre_selected || disableAll}
                      />
                    </Paper>
                  </Grid>
                )}

                {getFieldContent(item2.field_type, item2, index2)}
              </>
            ))}
        </Grid>
      </Paper>
    </>
  );
}
