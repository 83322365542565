import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomButtonCircularProgress from "../../../CircularProgressBar/index2";
import { Button } from "@material-ui/core";
import { praveshAxiosGetReq } from "../../../../api/BaseApi/apiBase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
  },
}));

const Resume = ({ data, userId }) => {
  const classes = useStyles();
  const [loadingResumeDownload, setLoadingResumeDownload] = useState(false);
  const resumeReference = useRef("");

  const downloadResume = async (url) => {
    setLoadingResumeDownload(true);

    try {
      const res = await praveshAxiosGetReq(url);
      if (res?.data?.success) {
        resumeReference.current = res?.data?.data?.link;
        if (res?.data?.data?.link) {
          window.open(res?.data?.data?.link, "blank").focus();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingResumeDownload(false);
    }
  };

  return (
    <>
      <br />
      <div className={classes.paper}>
        <Typography variant="h6">Resume</Typography>
        {/* {data?.student_user_details?.resume && (
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          Student Resume
        </Typography>
      )} */}
        {/* <div
        style={{
          display: "flex",
          // marginLeft: "10px",
          marginTop: "10px",
          justifyContent: "flex-start",
        }}
      >
        {data?.student_user_details?.resume && (
          <a target="blank" href={data?.student_user_details?.resume}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              style={{ width: "10rem", marginRight: ".5rem" }}
            >
              {"View Resume"}
            </Button>
          </a>
        )}
      </div> */}
        {/* <Typography variant="body1" style={{ marginTop: "10px" }}>
        College Student Resume
      </Typography> */}
        <div
          style={{
            display: "flex",
            // marginLeft: "10px",
            marginTop: "10px",
            justifyContent: "flex-start",
          }}
        >
          {(resumeReference.current ||
            data?.student_user_details?.college_resume) && (
            <a
              target="blank"
              href={
                resumeReference.current ||
                data?.student_user_details?.college_resume
              }
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                style={{ width: "10rem", marginRight: ".5rem" }}
              >
                {" "}
                Open
              </Button>
            </a>
          )}

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            style={{ width: "10rem" }}
            onClick={() => {
              const url = `api/college/college-saas/update-download-student-resume/?student_id=${data?.id}`;
              let resumeName = `${data?.first_name}:${data?.id}`;
              downloadResume(url, resumeName);
            }}
          >
            {loadingResumeDownload ? (
              <CustomButtonCircularProgress />
            ) : (
              "Generate"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Resume;
