import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Accordian/CustomAccordian";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  typography: {
    padding: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },

  expansionPanelSummaryContent: {
    margin: 0,
    // "&.expanded": {
    //   margin: 0,
    // },
  },

  // checkedIcon: {
  //   backgroundColor: "#137cbd",
  //   backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  //   "&:before": {
  //     display: "block",
  //     width: 16,
  //     height: 16,
  //     backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
  //     content: '""',
  //   },
  //   "input:hover ~ &": {
  //     backgroundColor: "#106ba3",
  //   },
  // },
}));

export default function DegreeSpecializationCustom({
  data,
  setData,
  specialization,
  setSpecialization,
  coursesArray,
  pushArray,
  setPushArray,
  errorMessage,
  loading,
  dataSelectedNewFormat,
  setDataSelectedNewFormat,
  disabled = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("");
  const classes = useStyles();
  const [displayArray, setDisplayArray] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [dataAll, setDataAll] = useState([]);
  const [specializationNew, setSpecializationNew] = useState([]);

  useEffect(() => {
    let temp = {};

    if (coursesArray?.length > 0 && data?.length > 0) {
      data.forEach((item) => {
        temp[item.degree_id] = item.specialization;
      });
      coursesArray.forEach((item) => {
        item?.degrees.forEach((item2) => {
          if (!Object.keys(temp).includes(String(item2.degree_id)))
            temp[item2.degree_id] = [];
        });
      });
      setSpecialization(temp);
    } else {
      let temp = {};

      coursesArray.forEach((item) => {
        item?.degrees?.forEach((item2) => {
          temp[item2?.degree_id] = [];
        });
      });
      setSpecialization(temp);
    }
    var push_arr = [...pushArray];
    push_arr.push(1);
    setPushArray(push_arr);
  }, [coursesArray?.length]);
  const [change_, setChange_] = useState("");

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setChange_(event.target.value);
    // var new_arr = courses.filter((product) =>
    //   product.specialization.filter((item) => item.specialization_name.startsWith(event.target.value) || product.degree_name.startsWith(event.target.value))
    // );
    var courses = [...coursesArray];
    var arr = [];
    courses.map((items) => {
      new_arr = items.degrees.map((element) => {
        return {
          ...element,
          specialization: element.specialization.filter((subElement) =>
            subElement.specialization_name
              .toLowerCase()
              .startsWith(event.target.value.toLowerCase())
          ),
        };
      });

      if (new_arr.every((item) => item.specialization.length > 0)) {
        new_arr.map((element) => {
          if (element.specialization.length > 0)
            return {
              ...element,
              specialization: element.specialization.filter((subElement) =>
                subElement.specialization_name
                  .toLowerCase()
                  .startsWith(event.target.value.toLowerCase())
              ),
            };
        });
      }

      if (new_arr.every((item) => item.specialization.length === 0)) {
        var new_arr = items.degrees.filter((item) =>
          item.degree_name
            .toLowerCase()
            .startsWith(event.target.value.toLowerCase())
        );
      }

      var nnn = new_arr.filter((item) => item.specialization.length > 0);
      new_arr = nnn;

      var obj = { ...items };
      obj["degrees"] = new_arr;

      arr.push(obj);
    });

    // var nnn = new_arr.filter((item) => item.specialization.length > 0);
    // new_arr = nnn;

    setDisplayArray(arr);
  };

  const handleClickNew = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    // var new_arr = courses.filter((product) =>
    //   product.specialization.filter((item) => item.specialization_name.startsWith(event.target.value) || product.degree_name.startsWith(event.target.value))
    // );
    var courses = [...coursesArray];

    setDisplayArray(courses);
  };

  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;
  const [unselect, setUnselect] = useState(false);
  const handleSelectAllSpecializations = (e, item) => {
    var arr = specialization[item.degree_id];
    var loc = specialization;
    var data_arr = [...data];
    const item_spec = [...item.specialization];
    if (item.select_all === false) {
      loc[item.degree_id] = item_spec;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        select_all: true,
        specialization: item_spec,
        education_id: item.education_id,
        education_name: item.education_name,
        degree_duration: item.degree_duration,
      };

      const found = data_arr.some((el) => el.degree_id === item.degree_id);
      if (found) {
        data_arr.forEach((inner_item) => {
          if (inner_item.degree_id === item.degree_id)
            inner_item.specialization = item_spec;
        });
      } else {
        data_arr.push(temp);
      }

      setData(data_arr);

      setSpecialization(loc);
    } else {
      var indexx;
      data_arr.map((inn, new_index) => {
        if (inn.degree_id === item.degree_id) indexx = new_index;
      });
      data_arr.splice(indexx, 1);

      setData(data_arr);
      loc[item.degree_id] = [];
      setSpecialization(loc);

      const found = data_arr.some(
        (el) => el.education_id === item.education_id
      );
      if (!found) {
        setUnselect(true);
      }
    }
    item.select_all = e.target.checked;
    item.specialization.map((i, index) => {
      i.selected = e.target.checked;
    });

    var result = false;
    if (coursesArray.length === data_arr.length) result = true;
    else if (coursesArray.length !== data_arr.length) {
      result = false;
      setSelectAll(false);
    }
    if (result) setSelectAll(true);

    if (data_arr.length === 0) setSelectAll(false);

    var push_arr = [...pushArray];
    push_arr.push(1);
    setPushArray(push_arr);
  };

  const handleSelectSingleSpecializations = (e, i, item) => {
    var arr = [...specialization[item.degree_id]];
    var loc = specialization;
    var data_arr = [...data];
    if (i.selected === false) {
      arr.push(i);
      loc[item.degree_id] = arr;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        specialization: arr,
        education_id: item.education_id,
        education_name: item.education_name,
        degree_duration: item.degree_duration,
      };
      if (specialization[item.degree_id].length === 1) data_arr.push(temp);

      const found = data_arr.some((el) => el.degree_id === item.degree_id);
      if (found) {
        var index = data_arr.findIndex(
          (loop) => loop.degree_id === item.degree_id
        );
        // data_arr.forEach((inner_item) => {
        data_arr[index].specialization = arr;
        // });
      } else {
        data_arr.push(temp);
      }

      setData(data_arr);

      i.selected = e.target.checked;
      setSpecialization(loc);
    } else {
      var indexx;
      arr.map((u, iin) => {
        if (u.specialization_id === i.specialization_id) {
          indexx = iin;
        }
      });

      arr.splice(indexx, 1);

      loc[item.degree_id] = arr;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        education_id: item.education_id,
        education_name: item.education_name,
        specialization: arr,
      };
      var indexing;

      data_arr.forEach((inn, new_index) => {
        if (inn.degree_id === item.degree_id) {
          if (inn.specialization.length === 1) {
            indexing = new_index;
            data_arr.splice(indexing, 1);
          } else {
            inn.specialization = arr;
          }
        }
      });

      setData(data_arr);

      setSpecialization(loc);
      i.selected = e.target.checked;
    }

    var result = !e.target.checked;

    for (var i in item.specialization) {
      if (item.specialization[i].selected === e.target.checked) {
        result = e.target.checked;
        break;
      }
    }
    if (result) item.select_all = e.target.checked;
    else if (data_arr.length === 0 || item.specialization.length === 1)
      item.select_all = false;
    // if (item.specialization.every((item) => item.selected === true)) {
    //   item.select_all = true;
    //   data.map((item_) => {
    //     if (item_.degree_id === item.degree_id) item_["select_all"] = true;
    //   });
    // } else{ item.select_all = false;
    //   data.map((item_) => {
    //     if (item_.degree_id === item.degree_id) item_["select_all"] = false;
    //   });
    // }
    var push_arr = [...pushArray];
    push_arr.push(1);
    setPushArray(push_arr);
  };

  const handleAll = (e) => {
    let arrayy = [...coursesArray];
    var loc = specialization;
    var new_arr = [...coursesArray];
    var spec = specializationNew;
    var temp = {};
    var listOfObjects = [];

    if (selectAll === false) {
      new_arr.forEach((item) => {
        var singleObj = {};
        singleObj["degree_id"] = item.degree_id;
        singleObj["degree_name"] = item.degree_name;
        singleObj["specialization"] = item.specialization;
        singleObj["select_all"] = item.select_all;
        listOfObjects.push(singleObj);
      });
      setData(listOfObjects);
      setSpecialization(spec);
    } else {
      let temp = {};
      arrayy.forEach((item) => {
        temp[item.degree_id] = [];
      });
      setData([]);
      setSpecialization(temp);
    }
    setSelectAll(e.target.checked);
    coursesArray.map((item, index1) => {
      item.select_all = e.target.checked;
      item.specialization.map((i, index) => {
        i.selected = e.target.checked;
      });
    });
    var push_arr = [...pushArray];
    push_arr.push(1);
    setPushArray(push_arr);
  };

  useEffect(() => {
    if (data?.length > 0) {
      var arr = [...data];
      var new_arr = [...dataSelectedNewFormat];
      var test_arr = [];
      arr.map((item) => {
        if (dataSelectedNewFormat?.length === 0) {
          var a = [];
          a.push(item);
          new_arr.push({
            education_id: item.education_id,
            education_name: item.education_name,
            degrees: a,
          });
        } else {
          const found = dataSelectedNewFormat.some(
            (el) => el.education_id === item.education_id
          );

          if (!containsObject(item.education_id, new_arr)) {
            var a = [];
            a.push(item);
            new_arr.push({
              education_id: item.education_id,
              education_name: item.education_name,
              degrees: a,
            });
          } else {
            if (unselect) {
              test_arr = new_arr.filter(
                (item2) => item2.education_id === item.education_id
              );
              new_arr = test_arr;
            } else {
              var index = new_arr.findIndex(
                (item2) => item2.education_id === item.education_id
              );

              var ee = arr.filter(
                (items) => items.education_id === item.education_id
              );
              new_arr[index].degrees = ee;
            }
          }
          // });
        }
      });
      setUnselect(false);
      setDataSelectedNewFormat(new_arr);
    }
  }, [pushArray?.length]);

  function containsObject(id, list) {
    var i;
    var boole = false;
    for (i = 0; i < list.length; i++) {
      if (list[i].education_id === id) {
        boole = true;
      }
    }
    return boole;
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
        setAnchorEl(null);
      }}
    >
      <div className={classes.root}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
          style={{ top: 6, zIndex: 10000 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={10}>
              <Paper
                style={{
                  width: 785,
                  borderRadius: 8,
                  maxHeight: 255,
                  overflowY: "scroll",
                }}
              >
                {!loading ? (
                  displayArray.map((items) => (
                    <>
                      <FormLabel
                        component="legend"
                        style={{
                          fontSize: 14,
                          color: "grey",
                          marginBottom: 5,
                          marginLeft: 20,
                          marginTop: 10,
                        }}
                      >
                        {items.education_name}
                      </FormLabel>
                      {items.degrees.map((item) => (
                        <Accordion
                          defaultExpanded={change_ === "" ? false : true}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            // className={{ expanded: classes.expanded }}
                            classes={{
                              content: classes.expansionPanelSummaryContent,
                              expanded: "expanded",
                            }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={item.select_all}
                                    onChange={(e) => {
                                      handleSelectAllSpecializations(e, item);
                                    }}
                                    // checked={gilad} onChange={handleChange}
                                    name="gilad"
                                  />
                                }
                                label={
                                  <span style={{ fontSize: 13 }}>
                                    {item.degree_name + " "}Courses
                                  </span>
                                }
                              />
                            </FormGroup>{" "}
                          </AccordionSummary>

                          <AccordionDetails>
                            <FormControl
                              component="fieldset"
                              className={classes.formControl}
                            >
                              <FormLabel
                                component="legend"
                                style={{
                                  fontSize: 14,
                                  color: "grey",
                                  marginBottom: 5,
                                }}
                              >
                                Specialization
                              </FormLabel>
                              <FormGroup>
                                {item.specialization.map((i, index) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={i.selected}
                                        onChange={(e) => {
                                          handleSelectSingleSpecializations(
                                            e,
                                            i,
                                            item
                                          );
                                        }}
                                        // checked={gilad} onChange={handleChange}
                                        name="gilad"
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: 13 }}>
                                        {i.specialization_name}
                                      </span>
                                    }
                                  />
                                ))}
                              </FormGroup>
                            </FormControl>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ))
                ) : (
                  <CircularProgress size={17} style={{ color: "black" }} />
                )}
              </Paper>
            </Fade>
          )}
        </Popper>

        {/* <Button onClick={handleClick("bottom-start")}>bottom-start</Button> */}
        <TextField
          disabled={disabled}
          variant="outlined"
          style={{ width: "100%" }}
          fullWidth
          className={clsx(classes.margin, classes.textField)}
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          inputProps={{ style: { padding: "16px" } }}
          InputProps={{
            classes: { root: classes.inputRoot },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                // onClick={handleClickNew("bottom-start")}
                >
                  {" "}
                  <ArrowDropDownIcon style={{ cursor: "pointer" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={
            change_
              ? handleClick("bottom-start")
              : handleClickNew("bottom-start")
          }
          label="Eligible Courses"
          onChange={handleClick("bottom-start")}
          error={errorMessage ? true : false}
          helperText={<span>{errorMessage}</span>}
        />
      </div>
    </ClickAwayListener>
  );
}
