import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import AllColleges from "./AllColleges";
import RightDropDown from "./RightDropDown";

function AllCollegesGrid({
  collegesList,
  setCollegesList,
  loadingFromData,
  setSearchState,
}) {
  return (
    <Box>
      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
        {collegesList?.map((college, index) => (
          <>
            {!loadingFromData ? (
              <Grid
                item
                md={6}
                style={{
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <AllColleges
                  id={college?.id}
                  details={college?.details}
                  disable={college?.details?.is_job_applied}
                  checked={college?.checked}
                  setCollegesList={setCollegesList}
                  setSearchState={setSearchState}
                />
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Skeleton
                  variant="rect"
                  width={560}
                  height={108}
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    marginLeft: 20,
                  }}
                />
              </Grid>
            )}
            {!loadingFromData ? (
              <Grid item md={6} style={{ borderBottom: "1px solid #E0E0E0" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    padding: "0px 20px 0px 20px",
                  }}
                >
                  <RightDropDown
                    index={index}
                    placementCycles={college?.placementCycles}
                    setCollegesList={setCollegesList}
                    error={college?.error}
                    // disable={!college?.checked}
                    setSearchState={setSearchState}
                    disable={
                      college?.details?.is_job_applied || !college?.checked
                    }
                  />
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  borderBottom: "1px solid #E0E0E0",
                  borderRight: "1px solid #E0E0E0",
                }}
              >
                <Skeleton
                  variant="rect"
                  width={560}
                  height={108}
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    marginLeft: 20,
                  }}
                />
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default AllCollegesGrid;
