import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { praveshAxiosGetReq } from "../../../../api/BaseApi/apiBase";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  overview: {
    paddingBottom: "15px",
    paddingTop: "5px",
  },

  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  textField: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
}));

export default function EducationForm({
  editEducation,
  setEditEducation,
  collegeList,
  boardsList,
  collegeNotListed,
  setCollegeNotListed,
  isCGPA,
  setIsCgpa,
  isEdit,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const [degreeList, setDegreeList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);

  const educationTypes = [
    { education_id: 1, education_name: "Secondary" },
    { education_id: 2, education_name: "Senior Secondary" },
    { education_id: 3, education_name: "Graduation (UG)" },
    { education_id: 4, education_name: "Post Graduation (PG)" },
    { education_id: 5, education_name: "PhD" },
    { education_id: 6, education_name: "Diploma" },
  ];

  useEffect(() => {
    setIsCgpa(editEducation?.is_grade_system);
  }, [editEducation?.is_grade_system]);

  const checkCollege = () => {
    if (
      editEducation?.college &&
      editEducation?.college.college !== 142 &&
      editEducation?.is_onboarded
    )
      return `&college_id=${editEducation.college}`;
    else return ``;
  };

  const getCollegeCheck = () => {
    return editEducation?.college && editEducation?.college !== 142
      ? editEducation?.college
      : null;
  };

  useEffect(() => {
    if (editEducation?.education && editEducation?.education > 2) {
      const getDegreeList = async () => {
        const url = `api/education/get_degree?education_level=${
          editEducation.education
        }${checkCollege()}`;
        try {
          const res = await praveshAxiosGetReq(url);
          if (res.data.success) {
            setDegreeList(res.data.data);
          } else {
            alert.error(res.data.error);
          }
        } catch (error) {
          alert.error(error.message);
          throw error;
        }
      };
      getDegreeList();
    }
  }, [editEducation.college, editEducation.education]);

  useEffect(() => {
    if (editEducation?.degree_id) {
      const getSpecialization = async () => {
        const url = `api/education/get_specialization?degree_id=${
          editEducation?.degree_id
        }${checkCollege()}`;
        try {
          const res = await praveshAxiosGetReq(url);
          if (res?.data?.success) {
            setSpecializationList(res?.data?.data);
          } else {
            alert.error(res?.data?.error);
          }
        } catch (error) {
          alert.error(error?.message);
          throw error;
        }
      };
      getSpecialization();
    }
  }, [editEducation.degree_id]);

  return (
    <>
      <div>
        <div className={classes.overview}>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Autocomplete
                  value={{
                    education_id: editEducation?.education,
                    education_name: editEducation?.highest_qualification,
                  }}
                  openOnFocus
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setEditEducation({
                        ...editEducation,
                        education: newValue?.education_id,
                        highest_qualification: newValue?.education_name,
                        degree_id: null,
                        degree: null,
                        specialization_id: null,
                        specialization_name: null,
                      });
                    }
                  }}
                  id="role345"
                  options={educationTypes}
                  getOptionLabel={(option) => option?.education_name}
                  renderOption={(option) => {
                    return (
                      <span
                        style={{ fontSize: 13 }}
                      >{`${option?.education_name}`}</span>
                    );
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: 8,
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Level of Education"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              {editEducation?.education > 2 && (
                <>
                  {collegeNotListed ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        className={classes.textField}
                        label="Enter College Name"
                        variant="outlined"
                        id="job-title"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        value={
                          editEducation?.temp_college_name
                            ? editEducation?.temp_college_name
                            : editEducation?.college_name
                        }
                        onChange={(event) => {
                          setEditEducation({
                            ...editEducation,
                            temp_college_name: event.target.value,
                            college: null,
                            college_name: null,
                            is_saas: null,
                          });
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        value={{
                          college_id: editEducation?.college,
                          college_name: editEducation?.college_name,
                          is_grade_system: editEducation?.is_grade_system,
                          is_onboarded: editEducation?.is_onboarded,
                          is_saas: editEducation?.is_saas,
                          percentage_factor: editEducation?.percentage_factor,
                        }}
                        openOnFocus
                        disabled={editEducation?.is_current ? true : false}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setEditEducation({
                              ...editEducation,

                              college: newValue?.college_id,
                              college_name: newValue?.college_name,
                              is_grade_system: newValue?.is_grade_system,
                              is_onboarded: newValue?.is_onboarded,
                              degree_id: null,
                              degree: null,
                              specialization_id: null,
                              specialization: null,
                              is_saas: newValue?.is_saas,
                              percentage_factor: newValue?.percentage_factor,
                            });
                          }
                        }}
                        id="role345"
                        options={collegeList}
                        getOptionLabel={(option) => option.college_name}
                        renderOption={(option) => {
                          return (
                            <span
                              style={{ fontSize: 13 }}
                            >{`${option.college_name}`}</span>
                          );
                        }}
                        className={classes.fieldStyling1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select College"
                            variant="outlined"
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {editEducation?.is_current ? (
                    <></>
                  ) : (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={collegeNotListed ? true : false}
                            onChange={() => {
                              if (collegeNotListed && !isEdit) {
                                setEditEducation({
                                  ...editEducation,
                                  degree_id: null,
                                  degree: null,
                                  specialization_id: null,
                                  specialization: null,
                                });
                              }
                              setCollegeNotListed(!collegeNotListed);
                            }}
                            name="checkedG"
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "14px",
                              /* fontWeight: "bold", */
                            }}
                          >
                            College not listed
                          </Typography>
                        }
                      />
                    </Grid>
                  )}

                  {/* degreeList */}
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      disabled={editEducation?.education ? false : true}
                      value={{
                        education_type: editEducation?.education,
                        education_type_name:
                          editEducation?.highest_qualification,
                        id: editEducation?.degree_id,
                        name: editEducation?.degree,
                        user_created: false,
                      }}
                      openOnFocus
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setEditEducation({
                            ...editEducation,
                            degree_id: newValue?.id,
                            specialization_id: null,
                            specialization: null,
                            education: newValue?.education_type,
                            highest_qualification:
                              newValue?.education_type_name,
                            degree: newValue?.name,
                            user_created: newValue?.user_created,
                          });
                        }
                      }}
                      id="role345"
                      options={degreeList}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Degree"
                          variant="outlined"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* specialization */}
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      disabled={editEducation?.degree_id ? false : true}
                      // value={editEducation.specialization_id}
                      value={{
                        degree_id: editEducation?.degree_id,
                        degree_name: editEducation?.degree,
                        specialization_id: editEducation?.specialization_id,
                        specialization_name: editEducation?.specialization,
                        user_created: editEducation?.user_created,
                      }}
                      openOnFocus
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setEditEducation({
                            ...editEducation,
                            specialization_id: newValue?.specialization_id,
                            specialization: newValue?.specialization_name,
                          });
                        }
                      }}
                      id="role345"
                      options={specializationList}
                      getOptionLabel={(option) => option.specialization_name}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.specialization_name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Specialization"
                          variant="outlined"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {editEducation?.is_saas ? (
                <Grid item md={12} xs={12}>
                  <TextField
                    className={classes.textField}
                    label="Enter Enrollment Id"
                    variant="outlined"
                    id="job-title"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    value={
                      editEducation?.enrollment_id
                        ? editEducation?.enrollment_id
                        : ""
                    }
                    onChange={(e) => {
                      setEditEducation({
                        ...editEducation,
                        enrollment_id: e.target.value,
                      });
                    }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {editEducation.education <= 2 && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      label="Enter School Name"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      variant="outlined"
                      id="job-title"
                      value={
                        editEducation?.temp_college_name
                          ? editEducation?.temp_college_name
                          : editEducation?.college_name
                      }
                      onChange={(event) => {
                        setEditEducation({
                          ...editEducation,
                          temp_college_name: event.target.value,
                          college: null,
                          college_name: null,
                          is_saas: null,
                        });
                      }}
                    />
                  </Grid>

                  {/* boards */}
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      value={{
                        id: editEducation?.board,
                        name: editEducation?.board_name,
                      }}
                      openOnFocus
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setEditEducation({
                            ...editEducation,
                            board: newValue?.id,
                            board_name: newValue?.name,
                          });
                        }
                      }}
                      id="role345"
                      options={boardsList}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.name}`}</span>
                        );
                      }}
                      className={classes.fieldStyling1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Board Name"
                          variant="outlined"
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {/*    {editEducation?.is_current && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      label={"Enter Enrollment Id"}
                      required
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      size="small"
                      variant="outlined"
                      id="custom-css-outlined-input"
                      onChange={(e) => {
                        setEditEducation({
                          ...editEducation,
                          enrollment_id: e.target.value,
                        });
                      }}
                      value={editEducation?.enrollment_id}
                    />
                  </Grid>
                </>
              )} */}

              <Grid item xs={12} style={{ marginBottom: "-10px" }}>
                <TextField
                  className={classes.textField}
                  label={isCGPA ? "Enter Your CGPA" : "Enter Your Percentage"}
                  required
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  variant="outlined"
                  id="custom-css-outlined-input"
                  type="number"
                  onChange={(e) => {
                    if (isCGPA)
                      setEditEducation({
                        ...editEducation,
                        cgpa: e.target.value,
                      });
                    else
                      setEditEducation({
                        ...editEducation,
                        percentage: e.target.value,
                      });
                  }}
                  value={
                    isCGPA ? editEducation?.cgpa : editEducation?.percentage
                  }
                />
              </Grid>
              {/*  {!isCGPA && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        width: "95%",
                        color: "#b0b6ba",
                      }}
                    >
                      Please convert CGPA to Percentage with your college grade
                      system.
                    </Typography>
                  </div>
                </Grid>
              )} */}

              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="outlined"
                    format="MM/yyyy"
                    openTo="year"
                    margin="normal"
                    views={["year", "month"]}
                    inputVariant="outlined"
                    id="date-picker-inline"
                    defaultValue="MM/YYYY"
                    label="Start Date"
                    value={
                      editEducation?.start_date
                        ? editEducation?.start_date
                        : null
                    }
                    onChange={(date, value) => {
                      setEditEducation({ ...editEducation, start_date: date });
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="outlined"
                    format="MM/yyyy"
                    openTo="year"
                    margin="normal"
                    views={["year", "month"]}
                    inputVariant="outlined"
                    id="date-picker-inline"
                    defaultValue="MM/YYYY"
                    label="End Date"
                    value={
                      editEducation?.end_date ? editEducation?.end_date : null
                    }
                    onChange={(date, value) => {
                      setEditEducation({ ...editEducation, end_date: date });
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
