import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import TableChartIcon from "@material-ui/icons/TableChart";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import InterviewScheduling from "../../DialogSection/InterviewScheduling";
import MoveStep from "../../DialogSection/MoveStep";
import DownloadResume from "../../DialogSection/MoveStep/DownloadResume";
import MoveStepReject from "../../DialogSection/MoveStepReject";
import Calendar from "../Calendar/index3";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
  button: {
    borderRadius: "8px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: ".3px solid #b0b6ba",
    width: "100%",
    boxShadow: "none",
    borderRadius: "8px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  dialogbtn: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",

    marginTop: 20,
    marginBottom: -5,
  },
}));

function TrackActionBar({
  filters,
  setFilters,
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  filterList,
  moveNextArray,
  moveToNext,
  setMoveToNext,
  MoveToNextStep,
  fromPublicTrack,
  validatorData,
  userRolesPermission,
  navbarData,
  data,
  tableColumns,
  setTableColumns,
}) {
  const [showTrack, setShowTrack] = useState(false);

  useEffect(() => {
    if (fromPublicTrack) {
      if (validatorData?.action_performed?.authority?.track === true) {
        setShowTrack(true);
      } else setShowTrack(false);
    } else {
      setShowTrack(true);
    }
  }, [validatorData]);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={1} style={{ display: "contents" }}>
          <Grid item xs={2} style={{ display: "flex" }}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                onKeyDown={(e) => {
                  if (filters.search) {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setFilters({
                        ...filters,
                        search_check: true,
                      });
                    }
                  }
                }}
                value={filters.search}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
              />
              {filters?.search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: "",
                      search_check: true,
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setFilters({
                    ...filters,
                    search_check: true,
                  });
                }}
              >
                {/* {!clickSearchButton &&  ? <SearchIcon /> : <ClearIcon />} */}
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={2} style={{ display: "flex" }}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Enrollment No."
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                value={filters.enrollment_search}
                onKeyDown={(e) => {
                  if (filters.enrollment_search) {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setFilters({
                        ...filters,
                        enrollment_search_check: true,
                      });
                    }
                  }
                }}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    enrollment_search: e.target.value,
                  });
                }}
              />
              {filters?.enrollment_search && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="enrollment_search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      enrollment_search: "",
                      enrollment_search_check: true,
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="enrollment_search"
                onClick={() => {
                  setFilters({
                    ...filters,
                    enrollment_search_check: true,
                  });
                }}
              >
                {/* {!clickSearchButton &&  ? <SearchIcon /> : <ClearIcon />} */}
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {filterList &&
            Object.keys(filterList)?.length > 0 &&
            Object.keys(filterList).map(
              (item) =>
                filterList[item].type === "autocomplete" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      id="country-select-demo"
                      options={filterList[item].data}
                      getOptionLabel={(option) => String(option.name)}
                      value={filters[filterList[item].key]}
                      onChange={(e, newValue) => {
                        setFilters({
                          ...filters,
                          [filterList[item].key]: newValue,
                        });
                      }}
                      style={{ background: "#fff", borderRadius: 8 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterList[item].heading}
                          inputProps={{
                            ...params.inputProps,
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )
            )}

          {/* <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel className={classes.floatingLabelFocusStyle}>
                College
              </InputLabel>
              <Select
                native
                value={state.age}
                onChange={handleChange}
                label="College"
                inputProps={{
                  style: {
                    padding: "16px",
                    borderRadius: "30px",
                    backgroundColor: "#fff",
                  },
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={2}>
            <Calendar
              title="Date"
              selectedDate={filters?.applied_on}
              setSelectedDate={(date) => {
                setFilters({ ...filters, applied_on: date });
              }}
              disablePast={false}
            />
          </Grid>
          {!fromPublicTrack && (
            <Grid item style={{ display: "grid" }}>
              <Tooltip title="Customize Table Field" placement="top">
                <Button
                  onClick={handleClick}
                  variant="outlined"
                  color="inherit"
                  style={{
                    height: "50px",
                    borderColor: "#b7b7b7",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <TableChartIcon style={{ color: "#757575" }} />
                </Button>
              </Tooltip>
              {/* <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                inputProps={{
                  style: {
                    padding: "16px",
                    borderRadius: "8px",
                  },
                }}
                variant="outlined"
                value={variantName}
                label="Customize Table Field"
                onChange={handleChange}
                // input={<OutlinedInput label="Tag" />}
                renderValue={(selected) =>
                  selected.map((x) => x.name).join(", ")
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      maxWidth: "200px",
                      borderRadius: "8px",
                    },
                  },
                }}
              > */}
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                }}
                transformOrigin={{
                  vertical: "top",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    // marginTop: "60px",
                  },
                }}
              >
                {tableColumns.map((variant) => (
                  <MenuItem key={variant.field} value={variant}>
                    {/*  <Checkbox
                        color="primary"
                        checked={!variant?.hide}
                        onChange={(e) => {
                          let tempArray = [...tableColumns];
                          var itemIndex = tempArray.findIndex(
                            (x) => x.field === variant.field
                          );
                          var item = tempArray[itemIndex];
                          item.hide = !e.target.checked;
                          tempArray[itemIndex] = item;
                          setTableColumns(tempArray);
                        }}
                      /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={!variant?.hide}
                          onChange={(e) => {
                            let tempArray = [...tableColumns];
                            var itemIndex = tempArray.findIndex(
                              (x) => x.field === variant.field
                            );
                            var item = tempArray[itemIndex];
                            item.hide = !e.target.checked;
                            tempArray[itemIndex] = item;
                            setTableColumns(tempArray);
                          }}
                        />
                      }
                      label={variant.headerName}
                    />
                    {/* <ListItemText primary={variant.displayName} /> */}
                  </MenuItem>
                ))}
              </Menu>
              {/* </Select> */}
              {/* </Tooltip> */}
            </Grid>
          )}

          {/* <Grid item xs={2}>
            <SubmittedAssignment />
          </Grid> */}
        </Grid>
      </div>
      {selectedRowsData?.length > 0 &&
        filters?.navbar_object?.tooltip !== "Left Company" &&
        filters?.navbar_object?.tooltip !== "Rejected" &&
        !data?.["niyukti_job_id"] && (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
            className={classes.dialogbtn}
          >
            {showTrack &&
              (fromPublicTrack ||
                userRolesPermission?.some(
                  (item) => item.access_control_key === "action_job_track"
                )) && (
                <>
                  <MoveStep
                    moveNextArray={moveNextArray}
                    selectedRowsData={selectedRowsData}
                    setSelectedRowsData={setSelectedRowsData}
                    setSelectedID={setSelectedID}
                    moveToNext={moveToNext}
                    setMoveToNext={setMoveToNext}
                    MoveToNextStep={MoveToNextStep}
                  />
                  {filters?.navbar_object?.tooltip !== "Hired" &&
                    filters?.navbar_object?.tooltip !== "Joined" && (
                      <MoveStepReject
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                        setSelectedID={setSelectedID}
                        moveToNext={moveToNext}
                        setMoveToNext={setMoveToNext}
                        MoveToNextStep={MoveToNextStep}
                        navbarData={navbarData}
                      />
                    )}

                  {
                    !fromPublicTrack &&
                      userRolesPermission?.some(
                        (item) => item.access_control_key === "manage_interview"
                      ) && (
                        // filters?.navbar_object?.heading?.includes("Round") && (
                        <InterviewScheduling
                          jobData={data}
                          selectedRowsData={selectedRowsData}
                          setSelectedRowsData={setSelectedRowsData}
                          setSelectedID={setSelectedID}
                          filters={filters}
                        />
                      )
                    // )
                  }
                  {!["Marked Absent"].includes(
                    filters?.navbar_object?.tooltip
                  ) && (
                    <MoveStepReject
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      setSelectedID={setSelectedID}
                      moveToNext={moveToNext}
                      setMoveToNext={setMoveToNext}
                      MoveToNextStep={MoveToNextStep}
                      navbarData={navbarData}
                      forMarkingAbsent={true}
                    />
                  )}

                  {selectedRowsData?.length > 1 && (
                    <DownloadResume
                      selectedRowsData={selectedRowsData}
                      setSelectedRowsData={setSelectedRowsData}
                      setSelectedID={setSelectedID}
                      data={data}
                    />
                  )}
                </>
              )}
          </Grid>
        )}
    </>
  );
}

export default TrackActionBar;
