export const sharerColumnsForTrack = [
  {
    hide: false,
    field: "enrollment_id",
    headerName: "Enrollment Id",
    show: true,
  },
  {
    hide: false,
    field: "name",
    headerName: "Name",
    show: true,
  },
  {
    hide: false,
    field: "email",
    headerName: "Email",
    show: true,
  },
  {
    hide: false,
    field: "phone",
    headerName: "Phone Number",
    show: true,
  },
  {
    field: "gender",
    headerName: "Gender",
    hide: false,
    show: true,
  },
  {
    hide: false,
    field: "batch",
    headerName: "Current Batch",
    show: true,
  },
  {
    hide: false,
    field: "degree_name",
    headerName: "Current Degree",
    show: true,
  },
  {
    hide: false,
    field: "course_name",
    headerName: "Current Course",
    show: true,
  },

  {
    field: "ugCollege",
    headerName: "College Name(UG)",
    hide: false,
    //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
    show: true,
  },

  {
    field: "ugdegree",
    headerName: "Degree(UG)",
    hide: false,
    //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "ugSpecialization",
    headerName: "Specialization(UG)",
    hide: false,
    //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "ugPercentage",
    headerName: "Percentage(UG)",
    hide: false,
    //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
    show: true,
  },

  {
    field: "pgCollege",
    headerName: "College Name(PG)",
    hide: false,
    //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "pgDegree",
    headerName: "Degree(PG)",
    hide: false,
    //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "pgSpecialization",
    headerName: "Specialization(PG)",
    hide: false,
    //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "pgPercentage",
    headerName: "Percentage(PG)",
    hide: false,
    //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
    show: true,
  },

  {
    field: "phdCollege",
    headerName: "College Name(Phd)",
    hide: false,
    //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "phdDegree",
    headerName: "Degree(Phd)",
    hide: false,
    //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "phdSpecialization",
    headerName: "Specialization(Phd)",
    hide: false,
    //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "phdPercentage",
    headerName: "Percentage(Phd)",
    hide: false,
    //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
    show: true,
  },
  // {
  //   field: "secondarySchool",
  //   headerName: "Secondary School",
  //   hide: false,
  //   //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
  //   show: true,
  // },
  {
    field: "secondaryBoard",
    headerName: "Secondary Board",
    hide: false,
    //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    show: true,
  },
  {
    field: "secondaryPercentage",
    headerName: "Secondary Percentage",
    hide: false,
    //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    show: true,
  },

  // {
  //   field: "seniorSecondarySchool",
  //   headerName: "Senior Secondary School",
  //   hide: false,
  //   // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
  //   //   ? false
  //   //   : true,
  //   show: true,
  // },
  {
    field: "seniorSecondaryBoard",
    headerName: "Senior Secondary Board",
    hide: false,
    // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
    //   ? false
    //   : true,
    show: true,
  },
  {
    field: "seniorSecondaryPercentage",
    headerName: "Senior Secondary Percentage",
    hide: false,
    //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    show: true,
  },

  {
    hide: false,
    field: "applied_on",
    headerName: "Applied On",
    show: true,
  },
  {
    hide: false,
    field: "assignment",
    headerName: "Assignment",
    show: true,
  },
];
export const getSharerColumnsForTrack = (isGradeSystem) => {
  return [
    {
      hide: false,
      field: "enrollment_id",
      headerName: "Enrollment Id",
      show: true,
    },
    {
      hide: false,
      field: "name",
      headerName: "Name",
      show: true,
    },
    {
      hide: false,
      field: "email",
      headerName: "Email",
      show: true,
    },
    {
      hide: false,
      field: "phone",
      headerName: "Phone Number",
      show: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      show: true,
    },
    {
      hide: false,
      field: "batch",
      headerName: "Current Batch",
      show: true,
    },
    {
      hide: false,
      field: "degree_name",
      headerName: "Current Degree",
      show: true,
    },
    {
      hide: false,
      field: "course_name",
      headerName: "Current Course",
      show: true,
    },
    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "pgCollege",
      headerName: "College Name(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    // {
    //   field: "secondarySchool",
    //   headerName: "Secondary School",
    //   hide: false,
    //   //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    //   show: true,
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
    //   //   ? false
    //   //   : true,
    //   show: true,
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: false,
      // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
      //   ? false
      //   : true,
      show: true,
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: isGradeSystem
        ? "Senior Secondary CGPA"
        : "Senior Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      hide: false,
      field: "applied_on",
      headerName: "Applied On",
      show: true,
    },
    // {
    //   hide: false,
    //   field: "assignment",
    //   headerName: "Assignment",
    // },
  ];
};

export const getAllColumnsStudentField = (
  isGradeSystem,
  forSharing = false
) => {
  return [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      show: true,
      hide: false,
    },
    {
      field: "name",
      headerName: "Name",
      show: true,
      hide: false,
    },
    {
      field: "email",
      headerName: "Email",
      show: true,
      hide: false,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      show: true,
      hide: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      show: true,
    },

    {
      field: "eligible_count",
      headerName: "Eligible",
      show: forSharing ? false : true,
      hide: false,
    },
    {
      field: "applied_count",
      headerName: "Applied",
      show: forSharing ? false : true,
      hide: false,
    },
    {
      field: "present_count",
      headerName: "Present",
      show: forSharing ? false : true,
      hide: false,
    },
    {
      field: "hired_count",
      headerName: "Hired",
      show: forSharing ? false : true,
      hide: false,
    },

    {
      field: "batch",
      headerName: "Current Batch",
      show: true,
      hide: false,
    },

    {
      field: "degree",
      hide: false,
      show: true,
      headerName: "Current Degree",
    },
    {
      field: "course",
      headerName: "Current course",
      show: true,
      hide: false,
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      headerName: isGradeSystem ? "Current CGPA" : "Current Percentage",
      show: true,
      hide: false,
    },

    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "pgCollege",
      headerName: "College Name(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    // {
    //   field: "secondarySchool",
    //   headerName: "Secondary School",
    //   hide: false,
    //   //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    //   show: true,
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
    //   //   ? false
    //   //   : true,
    //   show: true,
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: false,
      // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
      //   ? false
      //   : true,
      show: true,
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: isGradeSystem
        ? "Senior Secondary CGPA"
        : "Senior Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "status",
      headerName: "Status",
      show: true,
      hide: false,
    },

    {
      field: "college_resume",
      headerName: "Resume",
      show: true,
      hide: false,
    },
  ];
};

export const getColumnsStudentField = (
  isEducationGroup,
  isCollegeGroup,
  collegeId,
  isGradeSystem
) => {
  return [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      show: true,
      hide: false,
    },
    {
      field: "name",
      headerName: "Name",
      show: true,
      hide: false,
    },
    {
      field: "email",
      headerName: "Email",
      show: true,
      hide: false,
    },

    {
      field: "phone",
      headerName: "Phone Number",
      show: true,
      hide: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      show: true,
    },

    {
      field: "batch",
      headerName: "Current Batch",
      show: true,
      hide: false,
    },

    {
      field: "degree",
      hide: false,
      show: true,
      headerName: "Current Degree",
    },
    {
      field: "course",
      headerName: "Current course",
      show: true,
      hide: false,
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      headerName: isGradeSystem ? "Current CGPA" : "Current Percentage",
      show: true,
      hide: false,
    },
    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "pgCollege",
      headerName: "College Name(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    // {
    //   field: "secondarySchool",
    //   headerName: "Secondary School",
    //   hide: false,
    //   //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    //   show: true,
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
    //   //   ? false
    //   //   : true,
    //   show: true,
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: false,
      // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
      //   ? false
      //   : true,
      show: true,
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: isGradeSystem
        ? "Senior Secondary CGPA"
        : "Senior Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "applied_jobs",
      headerName: "Applied Jobs",
      show: true,
      hide: false,
    },
    {
      field: "status",
      headerName: "Status",
      show: true,
      hide: false,
    },
  ];
};

export const getColumns2StudentField = (
  isEducationGroup,
  isCollegeGroup,
  collegeId,
  isGradeSystem
) => {
  return [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      show: true,
      hide: false,
    },
    {
      field: "name",
      headerName: "Name",
      show: true,
      hide: false,
    },
    {
      field: "email",
      headerName: "Email",
      show: true,
      hide: false,
    },

    {
      field: "phone",
      headerName: "Phone Number",
      show: true,
      hide: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      show: true,
    },
    {
      field: "invite_status",
      headerName: "Status",
      hide: false,
      show: true,
    },

    {
      field: "reason_message",
      headerName: "Reason Message",
      hide: false,
      show: true,
    },
    {
      field: "batch",
      headerName: "Current Batch",
      show: true,
      hide: false,
    },

    {
      field: "degree",
      hide: false,
      show: true,
      headerName: "Current Degree",
    },
    {
      field: "course",
      headerName: "Current course",
      show: true,
      hide: false,
    },
    {
      field: isGradeSystem ? "cgpa" : "percentage",
      headerName: isGradeSystem ? "Current CGPA" : "Current Percentage",
      show: true,
      hide: false,
    },
    {
      field: "ugCollege",
      headerName: "College Name(UG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "ugdegree",
      headerName: "Degree(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugSpecialization",
      headerName: "Specialization(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "ugPercentage",
      headerName: isGradeSystem ? "CGPA(UG)" : "Percentage(UG)",
      hide: false,
      //hide: currentEducations?.includes(UG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "pgCollege",
      headerName: "College Name(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgDegree",
      headerName: "Degree(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgSpecialization",
      headerName: "Specialization(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "pgPercentage",
      headerName: isGradeSystem ? "CGPA(PG)" : "Percentage(PG)",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },

    {
      field: "phdCollege",
      headerName: "College Name(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdDegree",
      headerName: "Degree(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdSpecialization",
      headerName: "Specialization(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "phdPercentage",
      headerName: isGradeSystem ? "CGPA(Phd)" : "Percentage(Phd)",
      hide: false,
      //hide: currentEducations?.includes(PHD_EDU_TYPE) ? false : true,
      show: true,
    },
    // {
    //   field: "secondarySchool",
    //   headerName: "Secondary School",
    //   hide: false,
    //   //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
    //   show: true,
    // },
    {
      field: "secondaryBoard",
      headerName: "Secondary Board",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "secondaryPercentage",
      headerName: isGradeSystem ? "Secondary CGPA" : "Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },

    // {
    //   field: "seniorSecondarySchool",
    //   headerName: "Senior Secondary School",
    //   hide: false,
    //   // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
    //   //   ? false
    //   //   : true,
    //   show: true,
    // },
    {
      field: "seniorSecondaryBoard",
      headerName: "Senior Secondary Board",
      hide: false,
      // hide: currentEducations?.includes(SENIOR_SECONDARY_EDU_TYPE)
      //   ? false
      //   : true,
      show: true,
    },
    {
      field: "seniorSecondaryPercentage",
      headerName: isGradeSystem
        ? "Senior Secondary CGPA"
        : "Senior Secondary Percentage",
      hide: false,
      //hide: currentEducations?.includes(SECONDARY_EDU_TYPE) ? false : true,
      show: true,
    },
  ];
};

export const getColumns2HigherStudies = () => {
  return [
    {
      field: "enrollment_id",
      headerName: "Enrollment Id",
      show: true,
      hide: false,
    },
    {
      field: "name",
      headerName: "Name",
      show: true,
      hide: false,
    },
    {
      field: "email",
      headerName: "Email",
      show: true,
      hide: false,
    },

    {
      field: "phone",
      headerName: "Phone Number",
      show: true,
      hide: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: false,
      show: true,
    },
    {
      field: "batch",
      headerName: "Batch",
      show: true,
      hide: false,
    },

    {
      field: "degree",
      hide: false,
      show: true,
      headerName: "Current Degree",
    },
    {
      field: "course",
      headerName: "Current course",
      show: true,
      hide: false,
    },
    {
      field:  "percentage",
      headerName:  "Current Percentage",
      show: true,
      hide: false,
    },
    {
      field: "higherCollegeName",
      headerName: "College Name",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "higherUniveristyName",
      headerName: "University Name",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "state",
      headerName: "State",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "country",
      headerName: "Country",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "higherCollegeAddress",
      headerName: "College Address",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "higherDegree",
      headerName: "Higher Degree",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "specialization",
      headerName: "Specialization",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "qualifying_exam",
      headerName: "Qualifying Exam",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "courseStartDate",
      headerName: "Course Start Date",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    },
    {
      field: "courseEndDate",
      headerName: "Course End Date",
      hide: false,
      //hide: currentEducations?.includes(PG_EDU_TYPE) ? false : true,
      show: true,
    } 
  ];
};

