import { Chip, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { CompanyGet, jobProfileGet } from "../../../api/Assignment/action";
import { StudentJobApplyAPI } from "../../../api/ManageStudents/action";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
  },

  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "8px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
  },
}));

export default function ApplyJobProfile({
  selectedRowsData,
  setSelectedRowsData,
  selectedID,
  setSelectedID,
  studentList,
  collegeId,
  checkSelectedGroup,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMore, setShowMore] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [loading, setLoading] = React.useState();
  const [jobProfile, setJobProfile] = useState(null);
  const [jobProfileError, setJobProfileError] = useState(null);
  const [company, setCompany] = useState(null);
  const [companyError, setCompanyError] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCompany(null);
    setJobProfile(null);
    setOpen(false);
  };

  const handleDelete = (id, index) => {
    var arr = [...selectedRowsData];
    var ids = [];
    if (index !== -1) {
      arr.splice(index, 1);
      setSelectedRowsData(arr);
      arr.map((item) => {
        ids.push(item.id);
      });
      setSelectedID(ids);
    }
  };

  const handleSubmit = () => {
    if (validateData()) {
      var arr = [];
      selectedRowsData?.map((item) => arr.push(item.id));

      var data = {
        college_id: [collegeId],
        job_id: jobProfile?.id,
        student_id: arr,
      };
      dispatch(
        StudentJobApplyAPI(
          data,
          alert,
          history,
          location,
          studentList,
          handleClose,
          setLoading,
          collegeId,
          setSelectedRowsData
        )
      );
    }
  };

  const validateData = () => {
    var is_valid = true;
    var job_error = null;
    var company_error = null;
    if (!company) {
      is_valid = false;
      company_error = "Please Enter Company";
    }
    if (!jobProfile) {
      is_valid = false;
      job_error = "Please Enter Job Profile";
    }
    setJobProfileError(job_error);
    setCompanyError(company_error);
    return is_valid;
  };

  useEffect(() => {
    const filter = checkSelectedGroup();
    var url = "api/assignment/company_job_dropdown/";
    if (filter) {
      url = url + "?" + filter;
    }
    dispatch(CompanyGet(alert, history, location, url));
  }, []);

  useEffect(() => {
    if (company) {
      const filter = checkSelectedGroup();
      var url = `api/assignment/company_job_dropdown/?company_id=${company?.id}`;
      if (filter) {
        url = url + "?" + filter;
      }
      dispatch(jobProfileGet(alert, history, location, url));
    }
  }, [company]);
  const companyList = useSelector((state) => state?.assignment?.companyData);
  const jobList = useSelector((state) => state?.assignment?.jobProfileData);

  return (
    <div>
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
        style={{ height: "40px" }}
      >
        Apply to Job Profile
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Apply to a Job Profile</Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRowsData.slice(0, 5).map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRowsData.map((data, index) => {
                  let icon;

                  // if (data.label === "React") {
                  //   icon = <TagFacesIcon />;
                  // }

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(data.id, index)}
                        deleteIcon={<CloseIcon />}
                        label={data.name}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
          </div>
          {selectedRowsData?.length > 5 && (
            <Button
              className={classes.button}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                padding: "5px",
                marginLeft: "7px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
          <br />
          <div
            component="ul"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <Autocomplete
              id="country-select-demo"
              options={companyList}
              getOptionLabel={(option) => String(option.name)}
              value={company}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCompany(newValue);
                  setCompanyError(null);
                } else {
                  setJobProfile(null);
                }
              }}
              style={{ background: "#fff", borderRadius: 8 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Choose Company Name"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={companyError ? true : false}
                  helperText={<span>{companyError}</span>}
                />
              )}
            />
            <br />

            {jobList && (
              <Autocomplete
                id="country-select-demo"
                options={jobList}
                getOptionLabel={(option) => String(option.job_title)}
                value={jobProfile}
                onChange={(e, newValue) => {
                  setJobProfile(newValue);
                  setJobProfileError(null);
                }}
                style={{ background: "#fff", borderRadius: 8 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Job Profile"}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={jobProfileError ? true : false}
                    helperText={<span>{jobProfileError}</span>}
                  />
                )}
              />
            )}
            <br />
            {/* <Button
              onClick={handleClose}
              color="primary"
              style={{ display: "flex" }}
              startIcon={<AddCircleIcon />}
            >
              Add New Placement Cycle
            </Button> */}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              className={classes.button}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "12px", minWidth: "140px" }}
              className={classes.button}
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? <CustomButtonCircularProgress /> : "Add "}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
